import { calculateDerivedValue } from '@/utils/derivedValueUtils'
import _keyBy from 'lodash/keyBy'
// NOTE: If moving this data to the database, make sure to fetch it in nuxtServerInit
// preferable as a part of settings/fetch action to avoid unnecessary requests and keep this function synchronous
import REDIRECTS_TABLE from '@/constants/redirects'

/**
 * Redirects the user based on a regular expression match.
 * @param {Object} context - The context for the redirect. https://v2.nuxt.com/docs/internals-glossary/context/
 * @param {Function} context.redirect - The redirect function.
 * @param {Object} context.route - The current route object.
 * @param {Object} context.store - The store object.
 */
export default function redirectBasedOnRegex({ redirect, route, store }) {
  const school = store?.state?.settings?.school
  if (!school) return
  const path = route.fullPath
  const schoolRedirects = REDIRECTS_TABLE[school]
  if (!schoolRedirects) return
  for (const { input, output } of schoolRedirects) {
    const match = path.match(input)
    const hasMatch = match && match[0]
    if (hasMatch) {
      const newRoute = calculateDerivedValue(output, prepareInterpolationValues(match))
      return redirect(newRoute)
    }
  }
}

function prepareInterpolationValues (regexMatch = []) {
  return {
    ...Object.assign({}, [ ...regexMatch ]),
    ...regexMatch?.groups
  }
}
