export default {
  questions: {
    requisites: {
      config: {},
      dataKey: 'requisites',
      isRequired: true,
      label: 'Requisites',
      questionId: 'requisites',
      questionType: 'requisites',
      required: false,
    },
    career: {
      config: {
        multiple: false,
        options: [],
      },
      dataKey: 'career',
      label: 'Career',
      optionsEditable: true,
      questionId: 'career',
      questionType: 'careerSelect',
      required: false,
    },
    code: {
      config: {},
      dataKey: 'code',
      label: 'Program Code',
      questionId: 'code',
      questionType: 'text',
      required: false,
    },
    degreeMaps: {
      config: {
        fields: {
          actualCredits: {
            dataKey: 'actualCredits',
            label: 'Actual Credits',
          },
          area: {
            config: {
              allowFreeText: false,
              options: ['generalEducation', 'major', 'minor', 'elective'],
            },
            dataKey: 'area',
            label: 'Area',
          },
          contactHours: {
            dataKey: 'contactHours',
            label: 'Contact Hours',
          },
          courseRequirementGroup: {
            dataKey: 'courseRequirementGroup',
            label: 'Course Requirement Group',
          },
          courseRequirementGroupFreeText: {
            dataKey: 'courseRequirementGroupFreeText',
            label: 'Course Requirement Group (FreeText)',
          },
          criticality: {
            dataKey: 'criticality',
            label: 'Criticality',
          },
          clinical: {
            dataKey: 'clinical',
            label: 'Clinical',
          },
          degreeMapEffectiveCatalogYear: {
            dataKey: 'degreeMapEffectiveCatalogYear',
            label: 'Degree Map Effective Catalog Year',
          },
          degreeMapName: {
            dataKey: 'degreeMapName',
            label: 'Degree Map Name',
          },
          degreeMapNarrative: {
            dataKey: 'degreeMapNarrative',
            label: 'Degree Map Narrative',
          },
          displayedInCatalog: {
            dataKey: 'displayedInCatalog',
            label: 'Displayed in Catalog',
          },
          minimumGrade: {
            config: {
              allowFreeText: false,
              options: [],
            },
            dataKey: 'minimumGrade',
            label: 'Minimum Grade',
          },
          progressCredits: {
            dataKey: 'progressCredits',
            label: 'Progress Credits',
          },
          requirementSelect: {
            config: {
              allowFreeText: false,
            },
            dataKey: 'requirementSelect',
            label: 'Requirement Select',
          },
          requirementType: {
            config: {
              allowFreeText: false,
              options: [
                'courses',
                'requirementDesignations',
                'milestone',
                'courseAttributes',
              ],
            },
            dataKey: 'requirementType',
            label: 'Requirement Type',
          },
          semester: {
            config: {
              allowFreeText: false,
              options: ['Fall', 'Summer', 'Spring'],
            },
            dataKey: 'semester',
            label: 'Semester',
          },
          totalDegreeMapCredits: {
            dataKey: 'totalDegreeMapCredits',
            label: 'Total Degree Map Credits',
          },
          totalElectiveCredits: {
            dataKey: 'totalElectiveCredits',
            label: 'Total Elective Credits',
          },
          totalGeneralEducationCredits: {
            dataKey: 'totalGeneralEducationCredits',
            label: 'Total General Education Credits',
          },
          totalMajorCredits: {
            dataKey: 'totalMajorCredits',
            label: 'Total Major Credits',
          },
          totalMinorCredits: {
            dataKey: 'totalMinorCredits',
            label: 'Total Minor Credits',
          },
          year: {
            config: {
              allowFreeText: false,
              options: ['Year 1', 'Year 2', 'Year 3', 'Year 4'],
            },
            dataKey: 'year',
            label: 'Year',
          },
        },
      },
      dataKey: 'degreeMaps',
      questionId: 'degreeMaps',
      questionType: 'degreeMaps',
      required: true,
    },
    departments: {
      actions: [],
      config: {},
      dataKey: 'departments',
      isRequired: true,
      label: 'Department(s)',
      questionId: 'departments',
      questionType: 'departmentSelect',
      required: false,
    },
    description: {
      config: {},
      dataKey: 'description',
      label: 'Program Description',
      questionId: 'description',
      questionType: 'text',
      required: false,
    },
    level: {
      config: {
        multiple: false,
        options: ['Undergraduate', 'Graduate'],
      },
      dataKey: 'level',
      isRequired: true,
      label: 'Program Level',
      optionsEditable: true,
      questionId: 'level',
      questionType: 'select',
      required: false,
    },
    learningOutcomes: {
      actions: [],
      config: {
        fields: {
          activity: {
            dataKey: 'activity',
            editable: true,
            inputType: 'text',
            label: 'Activity',
            placeholder: 'Set Activity',
            rolesAllowedToEdit: [],
          },
          assessment: {
            dataKey: 'assessment',
            editable: true,
            inputType: 'text',
            label: 'Assessment',
            placeholder: 'Set Assessment',
            rolesAllowedToEdit: [],
          },
          justification: {
            dataKey: 'justification',
            editable: false,
            inputType: 'textarea',
            label: 'Justification',
            placeholder: 'Set Justification',
            rolesAllowedToEdit: [],
          },
          name: {
            actions: [],
            dataKey: 'name',
            editable: true,
            inputType: 'text',
            label: 'Name',
            placeholder: 'Set dsa dadasdasda',
            questionId: 'name',
            rolesAllowedToEdit: [],
          },
          objective: {
            dataKey: 'objective',
            editable: true,
            inputType: 'text',
            label: 'Objective',
            placeholder: 'Set Objective',
            rolesAllowedToEdit: [],
          },
          tags: {
            config: {
              multiple: true,
              taggable: true,
            },
            dataKey: 'tags',
            editable: true,
            inputType: 'select',
            label: 'Tags',
            placeholder: 'Set Tags',
            rolesAllowedToEdit: [],
          },
        },
      },
      dataKey: 'learningOutcomes',
      questionId: 'learningOutcomes',
      questionType: 'learningOutcomes',
      required: false,
    },
    status: {
      config: {
        options: ['Active', 'Inactive'],
      },
      dataKey: 'status',
      label: 'Status',
      questionId: 'status',
      questionType: 'select',
      required: false,
    },
  },
  template: [
    {
      children: [
        {
          children: [
            {
              id: 'description',
              type: 'question',
            },
          ],
          id: 'DTDff',
          type: 'row',
        },
        {
          children: [
            {
              id: 'departments',
              type: 'question',
            },
            {
              id: 'career',
              type: 'question',
            },
          ],
          id: 'qFyIq',
          type: 'row',
        },
        {
          children: [
            {
              id: 'startTerm',
              type: 'question',
            },
            {
              id: 'endTerm',
              type: 'question',
            },
          ],
          id: 'jlPF8',
          type: 'row',
        },
        {
          children: [
            {
              id: 'level',
              type: 'question',
            },
            {
              id: 'code',
              type: 'question',
            },
            {
              id: 'status',
              type: 'question',
            },
          ],
          id: 'EuiBu',
          type: 'row',
        },
      ],
      config: {
        icon: 'book',
        id: 'aoYks',
        title: 'Program Overview',
      },
      id: 'aoYks',
      type: 'card',
    },
    {
      children: [
        {
          children: [
            {
              id: 'learningOutcomes',
              type: 'question',
            },
          ],
          id: 'jUTBl',
          type: 'row',
        },
      ],
      config: {
        icon: 'book',
        id: '5oQ93',
        title: 'Learning Outcomes',
      },
      id: '5oQ93',
      type: 'card',
    },
    {
      children: [
        {
          children: [
            {
              id: 'degreeMaps',
              type: 'question',
            },
          ],
          id: 'D5eEy',
          type: 'row',
        },
      ],
      config: {
        icon: 'book',
        id: 'NNJ34',
        title: 'Degree Maps',
      },
      id: 'NNJ34',
      type: 'card',
    },
    {
      children: [
        {
          children: [
            {
              id: 'requisites',
              type: 'question',
            },
          ],
          id: 'JrUcS',
          type: 'row',
        },
      ],
      config: {
        icon: 'book',
        id: 'ojrrt',
        title: 'Requisites',
      },
      id: 'ojrrt',
      type: 'card',
    },
  ],
}
