<template>
  <ul>
    <li
      v-for="(link, index) of links"
      :key="index"
    >
      <NavDropdown
        v-if="link.type === 'group'"
        :item="link"
        :is-open="activeLinkIndex === index"
        :class="{'underline' : isActiveLink(link)}"
        class="link sub-link lg:relative"
        @toggle-dropdown="toggleDropdown(index)"
      />
      <NavLink
        v-else-if="link.type === 'link'"
        :link="link"
        :class="{'underline' : isActiveLink(link)}"
        :title="link.label"
        class="link nav-flat-link block w-full leading-6 hover:underline hover:opacity-70 transition ease-in-out duration-150"
      >
        {{ link.label }}
      </NavLink>
    </li>
  </ul>
</template>

<script>
import NavDropdown from './NavDropdown'
import NavLink from './NavLink'

export default {
  components: {
    NavDropdown,
    NavLink,
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    activeLinkIndex: {
      type: Number,
      default: null,
    },
  },
  computed: {
    currentPageFullPath() {
      return this.$route.fullPath
    },
  },
  watch: {
    $route() {
      this.toggleDropdown(null)
    },
  },
  methods: {
    isActiveLink(link) {
      if (link.url === '/') {
        return this.currentPageFullPath === link.url
      }
      return this.currentPageFullPath.includes(link.url)
    },
    toggleDropdown(index) {
      this.$emit('dropdown', this.activeLinkIndex === index ? null : index)
    },
  },
}
</script>
