export const state = () => ({
  recentCatalogPdf: undefined,
})

export const mutations = {
  SET_RECENT_CATALOG_PDF(state, recentCatalogPdf) {
    state.recentCatalogPdf = recentCatalogPdf
  },
}

export const actions = {
  async fetchRecentCatalogPdf({ commit }, { school, catalogId }) {
    try {
      const report = await this.$axios.$get(
        `${school}/pdf-reports/catalogs/${catalogId}`
      )
      commit('SET_RECENT_CATALOG_PDF', report)
    } catch (err) {
      if (
        // Backend returns "Catalog not found" error if catalog pdf has been never generated for catalog.
        err.response.status === 418 &&
        err.response.data.error === 'Catalog not found'
      ) {
        commit('SET_RECENT_CATALOG_PDF', undefined)
      } else {
        this.$bugsnag.notify(err)
        throw err
      }
    }
  },
}
