<template>
  <DefaultLayout />
</template>

<script>
/* "jcu" is based on "default" theme with slight variation */
import DefaultLayout from '@/layouts/default'

export default {
  components: {
    DefaultLayout,
  },
}
</script>
