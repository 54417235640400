<template>
  <div>
    <div v-if="shouldRenderLabel" class="flex">
      <button
        class="p-2 pl-4 flex flex-1 items-center space-x-4 rounded-lg transition ease-in-out duration-150 group-header"
        :class="{ 'cursor-pointer item-link hover:bg-gray-100':!isSubHeader, 'font-extrabold text-lg -mb-1 -ml-2':isSubHeader }"
        tabindex="0"
        type="button"
        :data-test="`${list.slug}-dropdown-button`"
        :aria-expanded="shouldRenderSubgroup.toString()"
        :aria-controls="`${list.slug}-panel`"
        @click="setActiveIndex(selectedIndex)"
        @keyup.enter="setActiveIndex(selectedIndex)"
      >
        <span class="flex-1 text-left">
          {{ list.label }}
        </span>
        <BaseIcon
          v-if="list.children.length > 0 && !isSubHeader"
          icon="angle-right"
          class="flex-2 ml-auto"
        />
      </button>
    </div>
    <div :id="`${list.slug}-panel`">
      <template v-if="shouldRenderSubgroup">
        <div>
          <div
            v-for="(link, index) in list.children"
            :key="index"
            :tabindex="activeIndex.length === selectedIndex.length && link.type === 'group' ? index : -1"
            class="nested-drop-item"
            @keydown="(event) => handleKeyDown(event, [...selectedIndex, index], link.type)"
          >
            <NavSubGroup
              v-if="link.type === 'group'"
              :list="link"
              :nested="true"
              :active-index="activeIndex"
              :selected-index="[...selectedIndex, index]"
              :set-active-index="setActiveIndex"
            />
            <NavLink
              v-else-if="activeIndex.length === selectedIndex.length && link.type === 'link'"
              :link="link"
              class="p-2 pl-4 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
            >
              <span class="leading-6 item-link">
                {{ link.label }}
              </span>
            </NavLink>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import _isEqual from 'lodash/isEqual'
import NavLink from './NavLink'

export default {
  name: 'NavSubGroup',
  components: { NavLink },
  props: {
    nested: {
      type: Boolean,
      required: false,
    },
    list: {
      type: Object,
      required: true,
    },
    activeIndex: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedIndex: {
      type: Array,
      required: false,
      default: () => [],
    },
    setActiveIndex: {
      type: Function,
      required: false,
      default: (v) => v,
    },
  },
  computed: {
    isSubHeader() {
      return _isEqual(this.activeIndex, this.selectedIndex)
    },
    shouldRenderSubgroup() {
      return (
        this.activeIndex.length >= this.selectedIndex.length &&
        _isEqual(
          this.activeIndex.slice(0, this.selectedIndex.length),
          this.selectedIndex
        )
      )
    },
    shouldRenderLabel() {
      return (
        this.activeIndex.length <= 1 ||
        (this.activeIndex.length <= this.selectedIndex.length &&
          _isEqual(
            this.selectedIndex.slice(0, this.activeIndex.length),
            this.activeIndex
          ))
      )
    },
  },
  methods: {
    handleKeyDown(event, index, linkType = 'link') {
      if (
        (linkType === 'group' || linkType === 'back') &&
        event.target === event.currentTarget
      ) {
        switch (event.key) {
          case ' ':
          case 'Enter':
            this.setActiveIndex(index)
            event.preventDefault()
            break
          default:
            break
        }
      }
    },
  },
}
</script>

<style scoped>
.sub-link {
  color: var(--interfaceFontColor, var(--text-gray-500));
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, 700);
}

.item-link {
  color: var(--interfaceFontColor, var(--text-gray-600));
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, 500);
}
.group-header {
  color: var(--interfaceFontColor, var(--text-gray-600));
  font-family: var(--sidebarElementsFontTypeface, inherit);
}
</style>
