import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import BaseIcon from './BaseIcon'

config.autoAddCss = false

library.add(fas)
library.add(faGoogle)
Vue.component('BaseIcon', BaseIcon)
