<template>
  <div class="wrapper flex flex-col justify-between items-stretch">
    <component
      :is="element.component"
      v-for="(element, index) of schema"
      :key="index"
      :content="element.content"
    />
  </div>
</template>

<script>
import { LogoBlock, FreeFormBlock, NavBlock, RowBlock, NewBlock } from './SchemaLayoutBlocks.jsx'

export default {
  components: {
    LogoBlock,
    FreeFormBlock,
    NavBlock,
    RowBlock,
    NewBlock,
  },
  props: {
    schema: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.wrapper >>> p {
  @apply text-sm;
  color: var(--footerElementsFontColor, var(--text-red-200));
}

.wrapper >>> a {
  @apply text-sm;
  color: var(--footerElementsFontColor, var(--text-red-200));
}

.wrapper >>> a:hover {
  text-decoration: underline;
}
.foot-headers {
  color: var(--footerElementsFontColor, var(--text-red-400));
  font-weight: var(--footerElementsFontWeight, 600);
}
</style>
