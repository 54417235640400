<template>
  <component :is="componentTag"
    class="color-theme-footer-font bg-theme-darkest pb-7 pt-8"
  >
    <div class="max-w-screen-xl px-4 mx-auto flex flex-col">
      <SchemaLayout
        v-if="footerSchema"
        :schema="footerSchema"
      />
      <div
        v-if="!hideDownloadCatalogPdf"
        class="flex pt-4 mx-4 mt-4"
      >
        <div class="flex-col w-1/2">
          <RecentPdfButton />
        </div>
        <div class="flex-col w-1/2 self-center">
          <CoursedogLink />
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SchemaLayout from './SchemaLayout'
import RecentPdfButton from '@/components/RecentPdfButton.vue'
import CoursedogLink from '@/components/CoursedogLink'

export default {
  components: { CoursedogLink, SchemaLayout, RecentPdfButton },
  computed: {
    ...mapState('settings', ['schoolDisplayName', 'settings']),
    ...mapGetters('settings', ['hideDownloadCatalogPdf', 'customAfterPageHtml']),
    footerSchema() {
      return this.settings.footer
    },
    logo() {
      return this.settings.footerImage
    },
    componentTag() {
      return this.customAfterPageHtml?.replace(/\s+/g, '').includes('<footer') ? 'div' : 'footer'
    },
  },
}
</script>

<style scoped>
.link {
  cursor: pointer;
}
footer {
  font-family: var(--footerElementsFontTypeface, inherit);
  font-weight: var(--footerElementsFontWeight, inherit);
  font-size: var(--footerElementsFontSize, unset);
}
</style>
