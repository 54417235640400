<template functional>
  <div class="w-full cursor-pointer navigation-sidebar-elements outline-none">
    <BaseAccordion
      v-if="props.item.type === 'group'"
      role="menuitem"
      :selected="props.selected"
      :in-navigation="true"
      :should-focus="props.shouldFocus"
    >
      {{ props.item.label }}
      <template #content>
        <BaseSidebarNav
          :items="props.item.children"
          class="ml-3"
        />
      </template>
    </BaseAccordion>
    <BaseSidebarLink
      v-if="props.item.type === 'link'"
      role="menuitem"
      class="outline-none"
      :link="props.item"
      :selected="props.selected"
      :should-focus="props.shouldFocus"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseSidebarItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    shouldFocus: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.navigation-sidebar-elements:last-child {
  border-bottom: none;
}
</style>
