export const state = () => ({
  currentPageLabel: null,
})

export const getters = {
  currentPageLabel: (state) => state.currentPageLabel,
}

export const mutations = {
  SET_CURRENT_PAGE_LABEL(state, label) {
    state.currentPageLabel = label
  },
}
