<template>
  <div role="menu">
    <BaseSidebarItem
      v-for="(item, index) of items"
      :key="index"
      :should-focus="!index"
      :selected="isSelected(item)"
      :item="item"
    />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isSelected(item) {
      if (this.$route.params.id) {
        // Home has a pageId of "", i.e. empty string, so must have separate logic to test if user is on home page
        if (this.$route.params.id === 'home') return item.pageId === ''
        return this.$route.params.id === item.pageId
      }
      return this.$route.path === item.url
    },
  },
}
</script>

<style lang="css">
.navigation-sidebar-element,
[role='menu'] .accordion-button {
  @apply rounded outline-none px-2 py-1 font-semibold text-left;
  color: var(--sidebarElementsFontColor, inherit);
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, inherit);
}

.navbar-background .navigation-sidebar-element,
[role='menu'] .accordion-button {
  color: var(--topbarElementsFontColor, var(--themeNavbarLink, inherit));
}

.navigation-sidebar-element.selected,
.navigation-sidebar-element:focus,
[role='menu'] .accordion-button:focus {
  @apply bg-theme-600 text-white;
}
</style>
