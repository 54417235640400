export const state = () => ({
  all: [],
  chosen: null,
})

export const mutations = {
  SET(state, departments) {
    state.all = departments
  },
  SET_CHOSEN(state, departments) {
    state.chosen = departments
  },
}

export const actions = {
  async fetch({ commit, rootState, state }, { school }) {
    const departmentsFilters = rootState.settings.departmentsFilters
    const today = new Date().toISOString().split('T')[0]

    const effectiveStartDate =
      rootState.settings.effectiveDatesRange.effectiveStartDate || today
    const effectiveEndDate =
      rootState.settings.effectiveDatesRange.effectiveEndDate

    const hasFilters =
      departmentsFilters && departmentsFilters.filters.length > 0
    const departments = await this.$axios.$get(`${school}/departments`)
    // Filter out all inactive departments based on status and effective date fields

    // if a department has an effective date, it must contain the current date, otherwise it is considered not active
    const activeDepartments = Object.values(departments).filter(
      (department) => {
        let isEffective = true

        if (
          department.effectiveStartDate &&
          department.effectiveStartDate > effectiveStartDate
        )
          isEffective = false

        if (
          effectiveEndDate &&
          department.effectiveEndDate &&
          department.effectiveEndDate < effectiveEndDate
        )
          isEffective = false

        return department.status !== 'Inactive' && isEffective
      }
    )
    commit('SET', activeDepartments)

    if (hasFilters) {
      try {
        const chosenDepartments = await this.$axios.$post(
          `${school}/departments-search`,
          {
            ...departmentsFilters,
          }
        )

        const chosenDepartmentsList = Object.values(chosenDepartments)
        if (chosenDepartmentsList && chosenDepartmentsList.length)
          commit('SET_CHOSEN', chosenDepartmentsList)
      } catch (e) {
        this.$bugsnag.notify(e)
        console.error('Error while fetching departments with filters', e)
      }
    }
  },
}
