<template>
  <component
    :is="elementTagType"
    v-if="hasLogo || hasSchoolName"
    v-bind="attributes"
    class="flex items-center font-semibold text-xl tracking-tight logo-link mr-4"
  >
    <template v-if="hasLogo">
      <img
        :src="image"
        class="img"
        :alt="schoolDisplayName"
      >
    </template>
    <template v-if="hasSchoolName">
      <span class="color-theme-topbar-font hover:opacity-80">
        <slot name="logo-text">
          {{ schoolDisplayName }} Catalog
        </slot>
      </span>
    </template>
  </component>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    externalUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('settings', ['schoolDisplayName', 'settings']),
    elementTagType() {
      return this.externalUrl ? 'a' : 'NuxtLink'
    },
    attributes() {
      return this.externalUrl
        ? { href: this.externalUrl, target: '_blank' }
        : { to: '/' }
    },
    homeLinkType() {
      if (
        typeof this.settings.homeLinkType === 'object' &&
        this.settings?.homeLinkType?.value
      ) {
        return this.settings?.homeLinkType?.value
      }
      return this.settings.homeLinkType
    },
    hasLogo() {
      return this.homeLinkType === 'logotype' || this.homeLinkType === 'logo'
    },
    hasSchoolName() {
      return this.homeLinkType === 'logotype' || this.homeLinkType === 'text'
    },
    image() {
      return this.settings.navImage || require(`~/static/coursedog-logo.png`)
    },
  },
}
</script>

<style lang="css" scoped>
.img {
  max-height: 90px !important;
  max-width: initial !important;
}
</style>
