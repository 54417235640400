<template>
  <div>
    <div class="sm:flex">
      <div class="font-medium mr-6 sm:block hidden">Search In: </div>
      <div
        v-for="entity in searchEntities"
        :key="entity.id"
        class="mr-6 mb-2 flex items-center"
      >
        <label :data-test="entity.id + 'Label'" class=" cursor-pointer items-center" :for="`search-option-${entity.id}`">
          <span class="flex-1 text-left">{{ entity.label }}</span>
        </label>
        <input
          :checked="entity.enabled"
          :id="`search-option-${entity.id}`"
          type="checkbox"
          class="ml-2"
          style="width: 16px; height: 16px;"
          @input="$emit('input', entity)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchEntities: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
