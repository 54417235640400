<template>
  <div class="container flex flex-col justify-between items-center">
    <component
      :is="element.component"
      v-for="(element, index) of schema"
      :key="index"
      :content="element.content"
    />
  </div>
</template>

<script>
import { LogoBlock, FreeFormBlock, NavBlock, RowBlock, NewBlock } from './SchemaLayoutBlocks.jsx'

export default {
  components: {
    LogoBlock,
    FreeFormBlock,
    NavBlock,
    RowBlock,
    NewBlock,
  },
  props: {
    schema: {
      type: Array,
      required: true,
    },
  },
}
</script>
