<template>
  <div>
    <button
      type="button"
      data-test-id="nav-dropdown-button"
      :class="[{'d-flex w-full': isMobile}, 'group space-x-2']"
      :aria-expanded="isOpen?'true':'false'"
      @click="$emit('toggle-dropdown')"
    >
      <span class="sub-link ml-1">{{ item.label }}</span>
      <svg
        :class="[{'rotate-180': isOpen}, {'-rotate-90 flex-2': isMobile}, 'transform h-5 w-5 transition ease-in-out duration-150 dropdown-icon']"
        viewBox="0 0 20 20"
        aria-hidden="true"
        role="img"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200"
      enter-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-150"
      leave-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <div
        v-show="isOpen"
        class="z-20 absolute mt-5 lg:left-1/2 lg:top-full w-auto nav-dropdown"
      >
        <div class="bg-white lg:shadow-lg lg:border lg:border-gray-200 lg:overflow-x-auto lg:rounded-lg lg:rounded-none pt-3 pb-2">
          <p
            v-if="activeIndex.length > 1"
            class="cursor-pointer hover:opacity-80 px-4 py-1 back-button"
            tabindex="0"
            @click="() => setActiveIndex(activeIndex.slice(0, -1))"
            @keydown="(event) => handleKeyDown(event, activeIndex.slice(0, -1), 'back')"
          >
            <BaseIcon
              icon="angle-left"
              class="mr-1"
            />
            Back
          </p>
          <p
            v-else-if="activeIndex.length > 0 && isMobile"
            class="cursor-pointer hover:opacity-80 px-4 py-1 back-button"
            @click="$emit('toggle-dropdown')"
          >
            <BaseIcon
              icon="angle-left"
              class="mr-1"
            />
            Back
          </p>
          <div
            v-for="(column, indexC) in columns"
            :key="indexC"
            class="lg:space-y-2 lg:my-2 px-4"
          >
            <div
              v-for="(link, index) in column"
              :key="index"
              class="drop-item"
            >
              <template v-if="!activeIndex[1] || activeIndex[0] === indexC">
                <NavSubGroup
                  v-if="link.type === 'group'"
                  :list="link"
                  :active-index="activeIndex"
                  :set-active-index="setActiveIndex"
                  :selected-index="[indexC, index]"
                />
                <NavLink
                  v-else-if="activeIndex.length <= 1 && link.type === 'link'"
                  :link="link"
                  :class="{'underline' : $route.path === link.url}"
                  class="p-2 pl-4 flex items-start space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                >
                  <div class="space-y-1">
                    <span class="item-link leading-6">
                      {{ link.label }}
                    </span>
                  </div>
                </NavLink>
              </template>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import NavLink from './NavLink'
import NavSubGroup from './NavSubGroup'

export default {
  components: {
    NavLink,
    NavSubGroup,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeIndex: [0],
      isMobile: false,
    }
  },
  computed: {
    columns() {
      return this.item.children.reduce(
        (acc, curr) => {
          const currentColumn = acc[acc.length - 1]
          if (
            currentColumn.length &&
            currentColumn[currentColumn.length - 1].type === 'group'
          ) {
            acc.push([curr])
          } else {
            currentColumn.push(curr)
          }
          return acc
        },
        [[]]
      )
    },
  },
  mounted() {
    this.checkIfMobile()
    window.addEventListener('resize', this.checkIfMobile)
  },
  destroyed() {
    if (process.browser) {
      window.removeEventListener('resize', this.checkIfMobile)
    }
  },
  methods: {
    setActiveIndex(index) {
      this.activeIndex = index
    },
    handleKeyDown(event, index, linkType = 'link') {
      if (
        (linkType === 'group' || linkType === 'back') &&
        event.target === event.currentTarget
      ) {
        switch (event.key) {
          case ' ':
          case 'Enter':
            this.setActiveIndex(index)
            event.preventDefault()
            break
          default:
            break
        }
      }
    },
    checkIfMobile() {
      this.isMobile = process.browser ? window.innerWidth < 1024 : false
    },
  },
}
</script>
<style scoped>
.group {
  @apply inline-flex items-center text-base leading-6 font-medium transition ease-in-out duration-150;
  color: var(--topbarElementsFontColor, inherit);
}
.group:hover {
  @apply underline opacity-80;
}
@media (min-width: 1024px) {
  .nav-dropdown {
    min-width: 21rem;
    transform: translateX(-50%);
  }
}
@media (max-width: 1023px) {
  .nav-dropdown {
    top: 90px;
    right: 0;
    width: 300px;
    height: calc(100vh - 100px);
    background-color: var(--theme-navbar-background, white);
  }
  .sub-link {
    font-weight: var(--sidebarElementsFontWeight, 400) !important;
    flex: 1;
  }
}
.drop-item {
  min-width: 200px;
}
.sub-link,
.sub-link:hover,
.sub-link:focus {
  color: var(--topbarElementsFontColor, inherit);
  font-family: var(--topbarElementsFontTypeface, inherit);
  font-weight: var(--topbarElementsFontWeight, inherit);
  text-align: left;
}

.item-link,
.back-button {
  color: var(--interfaceFontColor, var(--text-gray-700));
  font-family: var(--sidebarElementsFontTypeface, inherit);
  font-weight: var(--sidebarElementsFontWeight, 500);
}

svg.dropdown-icon > path {
  fill: var(--topbarElementsFontColor);
}
</style>
