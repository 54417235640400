<template>
  <div class="mb-0 w-full ui-font relative">
    <label
      for="search-input"
      class="visually-hidden"
    >Catalog Search Query</label>
    <span class="absolute left-0 w-12 h-full flex justify-center items-center">
      <BaseIcon
        v-if="isLoading"
        icon="spinner"
        class="spinner"
      />
      <BaseIcon
        v-else
        icon="search"
      />
    </span>
    <input
      id="search-input"
      v-focus="true"
      v-bind="$attrs"
      type="search"
      class="px-12 py-4 w-full rounded-md border-2 border-gray-300"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
    >
    <button
      v-if="value"
      type="button"
      aria-label="Press to clear search"
      class="absolute right-0 w-12 h-full items-center mr-2 justify-center"
      @click="$emit('input', '')"
    >
      <span class="pointer text-blue-600 font-medium mr-4">Clear</span>
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Type to search...',
    },
  },
}
</script>

<style scoped>
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
</style>
