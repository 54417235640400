<template>
  <!-- Only show if the path prop is not empty -->
  <nav
    v-if="path.length > 0"
    data-test="breadcrumbs"
    aria-label="breadcrumbs"
    class="flex flex-row"
  >
    <!-- Page Breadcrumbs -->
    <div
      v-for="(pathItem, index) in pathWithBase"
      :key="'path-item-' + pathItem.id + index"
      class="flex pathItem text-sm"
      :data-test="pathItem.label + '-test'"
    >
      <NuxtLink
        v-if="pathItem.type !== 'group'"
        class="pathLink color-theme-light hover:color-theme-base transition-all duration-300 ease-in-out underline"
        tabindex="0"
        :to="pathItem.url"
      >
        {{ pathItem.label }}
      </NuxtLink>
      <span
        v-else
        class="pathNoLink"
      >
        {{ pathItem.label }}
      </span>
      <!-- Only show arrow if this is not the last item in the path -->
      <span
        v-if="pathWithBase.length - 1 !== index"
        class="flex items-center mx-1 h-4 text-gray-700"
        data-test="arrow"
        role="presentation"
      >/</span>
    </div>
  </nav>
</template>

<script>
const BASE_PATH = [
  {
    _id: 'home',
    label: 'Home',
    url: '/',
  },
]

export default {
  props: {
    path: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    pathWithBase() {
      return [...BASE_PATH, ...this.path]
    },
  },
}
</script>

<style lang="css" scoped>
.pathItem {
  @apply items-center text-sm leading-6 font-medium;
}

.pathLink,
.pathNoLink {
  @apply text-gray-700 px-1 flex items-center;
}
.pathLink {
  @apply cursor-pointer transition duration-300 ease-in-out;
}

.pathLink:hover {
  @apply text-gray-800 rounded bg-gray-200;
}
</style>
