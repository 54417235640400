function _traverseSidebarPath(
  path,
  linkTree,
  fieldToCheck,
  routeComparisonValue,
  parents
) {
  for (const item of linkTree || []) {
    const itemParents = parents ? [...parents] : []

    if (item[fieldToCheck] === routeComparisonValue) {
      path.push(...itemParents, item)
    }

    if (item.children) {
      itemParents.push(item)
      _traverseSidebarPath(
        path,
        item.children,
        fieldToCheck,
        routeComparisonValue,
        itemParents
      )
    }
  }

  return path
}

function isHome(routeData) {
  // Hanlding explicit edge cases where router is on the home page
  return routeData.params.id === 'home' || routeData.path === '/'
}
function predefinedPage(url) {
  const predefinedPages = [
    {
      _id: 'courses',
      label: 'Courses',
      url: '/courses',
    },
    {
      _id: 'programs',
      label: 'Programs',
      url: '/programs',
    },
    {
      _id: 'departments',
      label: 'Departments',
      url: '/departments',
    },
  ]
  return predefinedPages.find((page) => {
    if (url?.includes(page.url)) {
      // 0 index is an empty string as url here starts with "/"
      if (url.split('/')[1] === page._id) {
        return true
      }
    }

    return false
  })
}
export function pagePath(routeData, linkTree, labelFallback) {
  if (isHome(routeData)) {
    return []
  }
  const predefPage = predefinedPage(routeData.path)
  if (predefPage) {
    return [
      predefPage,
      ...(routeData.params?.id
        ? [
            {
              _id: routeData.params.id,
              url: routeData.path,
              label: labelFallback,
            },
          ]
        : []),
    ]
  } else {
    let path = []

    let fieldToCheck = 'url'
    if (routeData.params.id) {
      fieldToCheck = 'pageId'
    }
    const routeComparisonValue =
      fieldToCheck === 'url' ? routeData.path : routeData.params.id

    if (linkTree) {
      path = _traverseSidebarPath(
        path,
        linkTree,
        fieldToCheck,
        routeComparisonValue
      )
    }

    const pathHead = path.slice(0, -1)
    const [lastElement] = path.slice(-1)
    const lastElementWithLabel = {
      url: routeData.path,
      label: labelFallback,
      ...lastElement,
    }
    return [...pathHead, lastElementWithLabel]
  }
}
