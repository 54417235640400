import { format } from 'date-fns'
import defaultCourseTemplate from './defaultCourseTemplate'
import defaultProgramTemplate from './defaultProgramTemplate'

export const state = () => ({
  host: undefined,
  sidebar: [],
  navbar: [],
  activeCatalog: undefined,
  catalogDisplayName: '',
  school: undefined,
  schoolDisplayName: '',
  coursePageTemplate: defaultCourseTemplate,
  coursePreviewTemplate: defaultCourseTemplate,
  programPageTemplate: defaultProgramTemplate,
  settings: {
    homeLinkType: process.env.HOME_LINK_TYPE,
    customLayout: false,
    enableFetchingRequisitesWithLatestRevisions: undefined,
    displayRequisiteIdInlineIfEntityNotFound: undefined,
    enableFetchingDegreeMapsCoursesWithLatestRevisions: undefined,
  },
  coursesFilters: null,
  programsFilters: null,
  instructorsFilters: null,
  departmentsFilters: null,
  effectiveDatesRange: {
    effectiveStartDate: undefined,
    effectiveEndDate: undefined,
  },
  featureFlags: {},
})

export const getters = {
  layout: (state) => state.settings.customLayout || 'default',
  instructorsFilters: (state) => state.instructorsFilters,
  school: (state) => state.school,
  customBeforePageHtml: (state) => state.settings.customBeforePageHtml,
  customAfterPageHtml: (state) => state.settings.customAfterPageHtml,
  effectiveDatesRangeAsString: (state) => {
    const start =
      state.effectiveDatesRange.effectiveStartDate ||
      format(new Date(), 'yyyy-MM-dd')
    const end = state.effectiveDatesRange.effectiveEndDate || start
    return [start, end].filter(Boolean).join(',')
  },
  hideCourseModal: (state) => state.settings.hideCourseModal || false,
  hideProgramModal: (state) => state.settings.hideProgramModal || false,
  showCreditsOnCourseModal: (state) =>
    state.settings.showCreditsOnCourseModal || false,
  hideDownloadCatalogPdf: (state) =>
    state.settings.hideDownloadCatalogPdf || false,
  enableFetchingRequisitesWithLatestRevisions: (state) =>
    state.settings.enableFetchingRequisitesWithLatestRevisions || false,
  enableFetchingDegreeMapsCoursesWithLatestRevisions: (state) =>
    state.settings.enableFetchingDegreeMapsCoursesWithLatestRevisions || false,
  displayRequisiteIdInlineIfEntityNotFound: (state) =>
    state.settings.displayRequisiteIdInlineIfEntityNotFound || false,
  catalogWideMetaTitle: (state) => {
    switch (state.school) {
      case 'stanford':
        return `${state.schoolDisplayName} Bulletin` // TODO: this will be dynamic in the future
      case 'duke_peoplesoft':
        return `${state.catalogDisplayName} Bulletin` // TODO: this will be dynamic in the future
      default:
        return `${state.schoolDisplayName} Catalog`
    }
  },
}

export const mutations = {
  SET_HOST(state, host) {
    state.host = host
  },
  SET_DISPLAY_NAME(state, displayName) {
    state.schoolDisplayName = displayName || ''
  },
  SET_CATALOG_DISPLAY_NAME(state, displayName) {
    state.catalogDisplayName = displayName || ''
  },
  SET_SETTINGS(state, settings = {}) {
    state.settings = {
      ...state.settings,
      ...settings,
    }
  },
  SET_SCHOOL(state, school) {
    state.school = school
  },
  SET_NAVIGATION(state, { sidebar, navbar }) {
    state.sidebar = sidebar || []
    state.navbar = navbar || []
  },
  SET_PAGE_TEMPLATES(
    state,
    { coursePageTemplate, programPageTemplate, coursePreviewTemplate }
  ) {
    if (coursePageTemplate) state.coursePageTemplate = coursePageTemplate
    if (programPageTemplate) state.programPageTemplate = programPageTemplate
    if (coursePreviewTemplate)
      state.coursePreviewTemplate = coursePreviewTemplate
  },
  SET_ACTIVE_CATALOG(state, catalogId) {
    state.activeCatalog = catalogId
  },
  SET_FILTERS(state, catalog) {
    state.coursesFilters = catalog.coursesFilters
    state.programsFilters = catalog.programsFilters
    state.instructorsFilters = catalog.instructorsFilters
    state.departmentsFilters = catalog.departmentsFilters
  },
  SET_EFFECTIVE_RANGE(state, { effectiveStartDate, effectiveEndDate }) {
    state.effectiveDatesRange.effectiveStartDate = effectiveStartDate
    state.effectiveDatesRange.effectiveEndDate = effectiveEndDate
  },
  SET_FEATURE_FLAGS(state, flags) {
    state.featureFlags = flags
  },
}

export const actions = {
  async fetch({ commit }, { url }) {
    const {
      data: { school, catalog, catalogSettings },
    } = await this.$axios.get(`/catalogs/urls?url=${url}`)
    commit('SET_HOST', url)
    commit('SET_ACTIVE_CATALOG', catalog.id)
    commit('SET_CATALOG_DISPLAY_NAME', catalog.displayName)
    commit('SET_SCHOOL', school)
    commit('SET_SETTINGS', {
      ...(catalog.websiteSettings || catalogSettings), // use catalog specific website settings else use default settings
      ...(process.env.THEME_OVERRIDE
        ? { customLayout: process.env.THEME_OVERRIDE }
        : {}),
      courseSearchConfigurationId: catalog.courseSearchConfigurationId,
      programSearchConfigurationId: catalog.programSearchConfigurationId,
      // TODO: Uncomment to try out custom theme
      // customLayout: 'damian',
      // fonts:
      //   'https://fonts.googleapis.com/css2?family=Inter:wght@500;700&family=Roboto+Slab&display=swap',
    })
    commit('SET_NAVIGATION', {
      sidebar: catalog.sidebar?.navigationList,
      navbar: catalog.navbar?.navigationList,
    })
    commit('SET_FILTERS', catalog)

    commit('SET_PAGE_TEMPLATES', {
      coursePageTemplate: catalog.coursePageTemplate,
      programPageTemplate: catalog.programPageTemplate,
      coursePreviewTemplate: catalog.coursePreviewTemplate,
    })

    commit('SET_EFFECTIVE_RANGE', {
      effectiveStartDate: catalog.effectiveStartDate,
      effectiveEndDate: catalog.effectiveEndDate,
    })

    return { school, catalogId: catalog.id }
  },
  async fetchDisplayName({ commit }, { school }) {
    const data =
      (await this.$axios.$get(`/admin/schools/displayNames/${school}`)) || {}
    commit('SET_DISPLAY_NAME', data.displayName)
  },
  initFlags({ commit }, { flags }) {
    commit('SET_FEATURE_FLAGS', flags)
  },
}
