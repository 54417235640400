<template>
  <a
    v-if="link.linkType === 'external'"
    :key="link.url"
    :href="link.url"
    :title="link.label"
    rel="noopener"
    target="_blank"
    :aria-label="link.label"
    class="navigation-sidebar-element block md:text-gray-900 text-white hover:text-theme-400"
  >
    {{ link.label }}
  </a>
  <NuxtLink
    v-else
    :to="`${correctedLink.url}`"
    :title="correctedLink.label"
    :aria-label="correctedLink.label"
    class="navigation-sidebar-element block md:text-gray-900 text-white hover:text-theme-400"
    :class="[selected && 'selected']"
  >
    {{ correctedLink.label }}
  </NuxtLink>
</template>
<script>
import { prebuildUrlCorrector } from '@/utils/url'
export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    correctedLink() {
      const link = this.link
      return link.pageType === 'pre-defined' ? prebuildUrlCorrector(link) : link
    },
  },
}
</script>
