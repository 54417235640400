<template>
  <AppModal
    :use-dimness="true"
    :width="768"
    @close="close"
  >
    <template #modal-header>
      <h1 class="text-xl leading-6 font-bold text-gray-900 mb-3 text-left px-4" id="modal-headline">
        Search
      </h1>
    </template>
    <template #modal-body>
      <div class="px-4">
        <div class="flex pb-4">
          <SearchInput
            v-model="searchQuery"
            class="flex-1"
            placeholder="Search . . ."
            data-test="search-keyup"
            @keyup.enter.native="search"
          />
          <BaseButton
            class="ml-4"
            style="width:120px;"
            data-test="search-click"
            @click="search"
          >
            Search
          </BaseButton>
        </div>
        <SearchOptions
          class="pt-4"
          :search-entities="searchEntities"
          @input="updateEntity"
        />
      </div>
    </template>
    <template #modal-footer>
      <div />
    </template>
  </AppModal>
</template>

<script>
import AppModal from '@/components/AppModal'
import SearchInput from '@/components/SearchInput'
import SearchOptions from '@/components/SearchOptions'
import useGlobalSearch from '@/composables/useGlobalSearch'

export default {
  setup() {
    const { searchEntities } = useGlobalSearch()
    return {
      searchEntities,
    }
  },
  components: {
    AppModal,
    SearchInput,
    SearchOptions,
  },
  data() {
    return {
      searchQuery: this.$route.query.q,
    }
  },
  computed: {
    enabledSearchEntitiesString() {
      return this.searchEntities
        .filter((el) => el.enabled)
        .map((el) => el.id)
        .join(',')
    },
  },
  created() {
    this.updateEnabledSearchEntities()
  },
  methods: {
    updateEnabledSearchEntities() {
      // update the "enabled" property on the searchEntity object
      // based on query params
      if (!this.$route.query.searchEntities) return
      const enabledSearchEntities = (this.$route.query.searchEntities || '')
        .split(',')
        .filter(Boolean)
      // update this.searchEntities array to reflect enabled values
      // defined in URL query
      this.searchEntities = this.searchEntities.map((el) => ({
        ...el,
        enabled: enabledSearchEntities.includes(el.id),
      }))
    },
    close() {
      this.$emit('close')
    },
    search() {
      this.$router.push({
        name: 'search',
        query: {
          page: 1,
          q: this.searchQuery,
          searchEntities: this.enabledSearchEntitiesString,
        },
      })
      this.close()
    },
    updateEntity(value) {
      const entity = this.searchEntities.find(({ id }) => id === value.id)
      entity.enabled = !entity.enabled
    },
  },
}
</script>
