<template>
  <a
    v-if="link.linkType === 'external'"
    :key="link.url"
    :href="link.url"
    :title="link.label"
    rel="noopener"
    target="_blank"
  >
    <slot/>
  </a>
  <NuxtLink
    v-else
    :key="link.url"
    :to="link.url"
    :title="link.label"
  >
    <slot/>
  </NuxtLink>
</template>
<script>
export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
}
</script>
