<template>
  <nav
    aria-label="Primary navigation"
    class="flex items-center justify-between flex-wrap navbar-background px-6 py-2"
    data-test="primary-nav"
  >
    <AppLogo />
    <div class="block md:hidden">
      <button
        type="button"
        class="flex items-center px-3 py-2 border rounded text-theme-400er border-theme-400 hover:text-white hover:border-white"
        aria-label="Toggle mobile navigation"
        :aria-expanded="isNavOpen"
        @click="toggleNav"
      >
        <BaseIcon
          icon="bars"
          role="presentation"
          :color="topbarElementsFontColor"
        />
      </button>
    </div>
    <div
      class="w-full block flex-grow md:flex md:items-center md:w-auto md:visible py-4"
      :class="{ 'hidden': !isNavOpen }"
    >
      <div
        class="hidden md:block md:flex-grow font-semibold"
        data-test="desktop-nav"
      >
        <template v-for="link of navbar">
          <template v-if="link.type === 'link'">
            <a
              v-if="link.linkType === 'external'"
              :key="link.url"
              :href="link.url"
              :title="link.label"
              rel="noopener"
              target="_blank"
              class="nav-link"
            >
              {{ link.label }}
            </a>
            <NuxtLink
              v-else
              :key="link.url"
              :to="link.url"
              :title="link.label"
              class="nav-link"
            >
              {{ link.label }}
            </NuxtLink>
          </template>
        </template>
      </div>
      <BaseSidebarNav
        class="md:hidden -mx-4"
        :items="navbar"
      />
      <BaseSidebarNav
        class="md:hidden -mx-4"
        :items="sidebar"
      />
      <form
        class="search relative md:mt-0 mt-4"
        name="searchForm"
        role="search"
        @submit.prevent
      >
        <!-- We don't want it as `type="search"`, as browsers add x under search icon for this type. -->
        <!-- Alternatively we could use these styles, if we need it to be `type="search"`: https://stackoverflow.com/a/9422689 -->
        <input
          :value="searchQuery"
          type="text"
          placeholder="Type to search"
          name="search"
          title="Catalog search"
          aria-label="Search text input"
          data-test="navbar-search"
          class="search__input bg-white rounded-full px-4 w-full py-2 focus:outline-none border-2 border-transparent focus:border-theme-200"
          @input="searchDebounced($event.target.value)"
        >
        <button
          class="search__button"
          type="button"
          role="button"
          name="searchButton"
          aria-label="Press to search"
          @click="search"
        >
          <BaseIcon icon="search" />
        </button>
      </form>

    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import AppLogo from '@/components/AppLogo'
import { debounce } from '@/utils/generalUtils'

export default {
  components: { AppLogo },
  data() {
    return {
      searchQuery: this.$route.query.q,
      isNavOpen: false,
    }
  },
  computed: {
    ...mapState('settings', ['sidebar', 'navbar', 'settings']),
    accessibilityAttrs() {
      return this.$route.name === 'search'
        ? { 'aria-controls': 'search-results' }
        : {}
    },
    topbarElementsFontColor() {
      return this.settings.fontsConfig?.topbarElementsFontColor || 'inherit'
    },
  },
  watch: {
    $route() {
      this.isNavOpen = false
    },
    '$route.query.q'() {
      this.searchQuery = this.$route.query.q
    },
  },
  methods: {
    searchDebounced: debounce(function(v) {
      this.searchQuery = v
      this.search()
    }, 500),
    search() {
      this.$router.push({
        name: 'search',
        query: {
          page: 1,
          ...this.$route.query,
          q: this.searchQuery,
        },
      })
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen
    },
  },
}
</script>

<style>
.nav-link {
  @apply no-underline block mt-0 px-3 py-2;
  color: var(--topbarElementsFontColor, var(--theme-navbar-link, #ffffff));
  font-family: var(--topbarElementsFontTypeface, inherit);
  font-weight: var(--topbarElementsFontWeight, inherit);
}

.nav-link:hover {
  color: var(--topbarElementsFontColor, var(--theme-navbar-link, #ffffff));
  font-family: var(--topbarElementsFontTypeface, inherit);
  font-weight: var(--topbarElementsFontWeight, inherit);
  opacity: 0.7;
}

.nav-link.nuxt-link-exact-active {
  @apply rounded;
  background: rgba(0, 0, 0, 0.25);
}

.navbar-background {
  background-color: var(--theme-navbar-background, var(--theme-dark, #003525));
}

.search-input-wrapper {
  @apply flex justify-between bg-white rounded-full px-4 relative;
  min-width: 300px;
}

.search__input {
  @apply shadow-sm;
  min-width: 300px;
}

.search__input:hover {
  @apply shadow-2xl;
}

.search__input:focus {
  @apply shadow-2xl;
}

.search__button {
  @apply absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}

.search__input,
.search__button {
  font-family: var(--interfaceFontTypeface, inherit);
  font-weight: var(--interfaceFontWeight, inherit);
  color: var(--interfaceFontColor, inherit);
}

@screen md {
  .nav-link {
    @apply inline-block mt-0;
  }
}
</style>
