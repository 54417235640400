<template>
  <footer
    class="bg-theme-900 p-6 pb-7 pt-8"
    
  >
    <SchemaLayout v-if="footerSchema" :schema="footerSchema"/>
    <div v-if="!hideDownloadCatalogPdf" class="flex">
      <div class="flex-col w-1/2">
        <RecentPdfButton />
      </div>
      <div class="flex-col w-1/2 self-center">
        <CoursedogLink/>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import RecentPdfButton from './RecentPdfButton.vue'
import CoursedogLink from './CoursedogLink'
import SchemaLayout from './SchemaLayout'

export default {
  components: { CoursedogLink, SchemaLayout, RecentPdfButton },
  computed: {
    ...mapState('settings', ['schoolDisplayName', 'settings']),
    ...mapGetters('settings', ['hideDownloadCatalogPdf']),
    footerSchema() {
      return this.settings.footer
    },
    logo() {
      return this.settings.footerImage
    },
  },
}
</script>

<style scoped>
.link {
  cursor: pointer;
}
footer {
  color: var(--footerElementsFontColor, inherit);
  font-family: var(--footerElementsFontTypeface, inherit);
  font-weight: var(--footerElementsFontWeight, inherit);
}

.container {
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  color: var(--footerElementsFontColor, white);
}

.container >>> p {
  @apply text-sm;
}

.container >>> a {
  @apply text-sm;
  color: var(--footerElementsFontColor, white);
}

.container >>> a:hover {
  text-decoration: underline;
}
</style>
