import decamelize from 'decamelize'
import { mapState, mapGetters } from 'vuex'
import useFeatureFlags from '../composables/useFeatureFlags'
import {
  VUE_DEV_TOOLS,
  FONT_LIBRARY,
  NO_INDEX,
} from '../constants/featureFlags'
import { loadInternalFont, loadExternalFont } from '@/utils/fonts'

function getFontLink(fontsHref) {
  return {
    href: fontsHref,
    rel: 'stylesheet',
  }
}

const IGNORED_SETTINGS = [
  'footer',
  'navbar',
  'navbarId',
  'customBeforePageHtml',
  'customAfterPageHtml',
  'gtmId',
  'customLayout',
  'fontsConfig',
  'favicon',
  'navImage',
]

function fontRecordsToCSSVariables(fontConfig = {}) {
  const fontVariables = Object.entries(fontConfig).reduce((acc, currConfig) => {
    let [key, value] = currConfig
    // Filter out font file related keys
    if (['Source', 'Path'].some((ending) => key.endsWith(ending))) return acc
    // Convert internal font file name to typeface variable
    if (key.endsWith('Internal')) {
      key = key.replace('Internal', 'Typeface')
      value = value?.name || ''
    }
    acc[`--${key}`] = value
    return acc
  }, {})
  return fontVariables
}

export default {
  computed: {
    ...mapState('settings', ['settings', 'school']),
    ...mapGetters('settings', ['catalogWideMetaTitle']),
    themeVariables() {
      return Object.keys(this.settings).reduce((obj, key) => {
        if (IGNORED_SETTINGS.includes(key)) return obj
        const variable = `--${decamelize(key, '-')}`

        return {
          ...obj,
          [variable]: this.settings[key],
        }
      }, {})
    },
    favicon() {
      return this.settings.favicon || 'coursedog-favicon.ico'
    },
    fontsConfig() {
      return fontRecordsToCSSVariables(this.settings.fontsConfig)
    },
  },
  setup() {
    const { enableDevtools, enableFontLibrary, noIndex } = useFeatureFlags({
      enableDevtools: VUE_DEV_TOOLS,
      enableFontLibrary: FONT_LIBRARY,
      noIndex: NO_INDEX,
    })

    return { enableDevtools, enableFontLibrary, noIndex }
  },
  mounted() {
    this.applyFontLibrary()
    this.updateDevtoolsFlag()
  },
  methods: {
    updateDevtoolsFlag() {
      // Based on what the feature flag returns, this should either
      // set the value in localstorage or else remove the value from
      // localstorage
      if (this.enableDevtools) {
        localStorage.setItem('__SECRETLY__ENABLE__VUE_DEVTOOLS__', true)
      } else {
        localStorage.removeItem('__SECRETLY__ENABLE__VUE_DEVTOOLS__')
      }
    },
    applyFontLibrary() {
      if (!this.enableFontLibrary) return
      const fontConfig = this.settings.fontsConfig || {}
      Object.keys(fontConfig)
        .filter((key) => key.endsWith('ExternalSource'))
        .forEach((key) => {
          const isExternal = fontConfig[key]
          if (isExternal) {
            const prefix = key.replace('Source', '')
            const fontPath = fontConfig[`${prefix}Path`]
            loadExternalFont(fontPath)
          } else {
            const fontKey = `${key.replace('ExternalSource', '')}Internal`
            const fontValue = fontConfig[fontKey] || {}
            loadInternalFont(fontValue.name, fontValue.path)
          }
        })
    },
  },
  head(context) {
    return {
      title: this.catalogWideMetaTitle,
      meta: [
        ...(this.noIndex
          ? [
              {
                hid: 'robots',
                name: 'robots',
                content: 'noindex',
              },
            ]
          : []),
        {
          name: 'author',
          content: this.catalogWideMetaTitle,
        },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          hid: 'description',
          name: 'description',
          content: this.catalogWideMetaTitle,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.catalogWideMetaTitle,
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: this.catalogWideMetaTitle,
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: `${this.school}-catalog.coursedog.com`,
        },
        { hid: 'og:description', name: 'og:description', content: '' },
        { hid: 'og:type', name: 'og:type', content: 'website' },
        {
          hid: 'og:image',
          name: 'og:image',
          content: this.favicon,
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: this.favicon,
        },
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.favicon,
        },
        this.settings.fonts ? getFontLink(this.settings.fonts) : {},
        ...(this.settings.fontsConfig?.typefaceLink
          ? [getFontLink(this.settings.fontsConfig.typefaceLink)]
          : []),
      ],
    }
  },
}
