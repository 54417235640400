<template functional>
  <table
    :class="[
      data.staticClass,
      data.class,
      'ui-font',
    ]"
  >
    <thead
      class="base-table__head"
    >
      <tr>
        <slot name="header"/>
      </tr>
    </thead>
    <tbody
      class="base-table__body"
    >
      <template
        v-if="Array.isArray(props.rows) ? props.rows.length > 0 : Object.keys(props.rows).length > 0"
      >
        <tr
          v-for="(row, key) in props.rows"
          :key="key"
          :class="[props.clickable && 'cursor-pointer hover:bg-gray-100', !props.clickable && 'tableRowLocked']"
          tabindex="0"
          role="row"
          @keyup.enter="listeners.select && listeners.select(row, key)"
          @click="listeners.select && listeners.select(row, key)"
        >
          <slot v-bind="{ row, key }"/>
        </tr>
      </template>
      <!-- TODO -->
      <slot
        v-else
        name="noData"
      >
        <tr>
          <td colspan="99">
            <span>No {{ props.resourceName || 'Items' }} Found</span>
          </td>
        </tr>
      </slot>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    rows: {
      type: [Object, Array],
      required: true,
    },
    resourceName: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
.tableRowLocked:hover {
  background-color: transparent;
}
</style>
<style>
.base-table__head th {
  font-family: var(--interfaceFontTypeface, inherit);
  font-weight: var(--interfaceFontWeight, inherit);
  color: var(--interfaceFontColor, inherit);
}
.base-table__body td a {
  font-family: var(--interfaceFontTypeface, inherit);
  font-weight: var(--interfaceFontWeight, inherit);
  color: var(--interfaceFontColor, inherit);
}
</style>
