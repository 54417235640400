/**
 * @file The file is concerned about all the utility methods related to LaunchDarkly
 * launchdarkly-node-server-sdk dependency is loaded with the flag of process.server
 * since this cannot be loaded on the client side
 */

import { useContext } from '@nuxtjs/composition-api'
import * as LDClient from 'launchdarkly-js-client-sdk'

// eslint-disable-next-line import/no-mutable-exports
export let featureFlagsClient

const clientStub = {
  identify: () => {},
  flags: {},
  ready: true,
  isStub: true,
  on: () => {},
}

/**
 * Function to laod and intialise the launchdarkly client
 *
 * @param {object} user       User object that is to be recognised by launchdarkly
 * @param {string} user.key   Key of the user to uniquely identify the user on launchdarkly
 * @param {object} object     Optional stub which is to be used to initialise the
 *                            launchdarkly client in case the ld client id is
 *                            missing
 */
export function loadLaunchDarkly(user, { stub } = { stub: clientStub }) {
  const { VUE_APP_LAUNCH_DARKLY_CLIENT_ID } = process.env
  if (VUE_APP_LAUNCH_DARKLY_CLIENT_ID) {
    const { store } = useContext()

    featureFlagsClient = LDClient.initialize(
      VUE_APP_LAUNCH_DARKLY_CLIENT_ID,
      user,
      {
        bootstrap: store.state.settings.featureFlags,
      }
    )
  } else {
    featureFlagsClient = stub
  }
}
