<template>
  <transition
    appear
    name="fade-in"
  >
    <div
      key="modal-backdrop"
      v-shortkey="['esc']"
      :class="{ 'modal-backdrop--dimness': useDimness }"
      class="fixed inset-0 overflow-y-auto z-1000"
      @shortkey="$emit('close')"
    >
      <FocusLock>
        <transition
          appear
          name="slide-up"
        >
          <div
            data_test="modal-backdrop"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
            v-show="show"
            key="modal"
            data-test="modal-show"
            class="modal flex items-center justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-"
          >
            <div
              v-if="useDimness"
              data-test="modal-dimness"
              class="fixed inset-0 transition-opacity"
              @click="close"
            >
              <div class="absolute inset-0 bg-gray-500 opacity-50"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div
              v-on-clickaway="clickaway"
              class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full modal-dialog"
              data-test="modal-dialog"
              :style="widthWithUnit"
            >
              <div
                v-if="showX"
                class="absolute top-0 right-0 pt-4 pr-4 flex sm:-ml-10 sm:pr-4"
              >
                <button
                  data-test="closeby-x-btn"
                  aria-label="Close modal"
                  class="text-gray-300 hover:text-black transition ease-in-out duration-150 focus:outline-none"
                  @click="closeByX"
                >
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                    role="img"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:text-left flex-1">
                    <div id="modal-title">
                      <slot name="modal-header" />
                    </div>
                    <div
                      class="modal-body"
                      :class="{ 'overflow-none': noBodyOverflow }"
                    >
                      <slot name="modal-body"></slot>
                    </div>
                  </div>
                </div>
              </div>
              <slot name="modal-footer">
                <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                      type="button"
                      aria-label="Close modal"
                      data-test="close-button"
                      class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5 ui-font"
                      @click="close"
                    >
                      Close
                    </button>
                  </span>
                </div>
              </slot>
            </div>
          </div>
        </transition>
      </FocusLock>
    </div>
  </transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import FocusLock from 'vue-focus-lock'

export default {
  components: {
    FocusLock,
  },
  mixins: [clickaway],
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    useDimness: {
      type: Boolean,
      default: false,
    },
    showX: {
      type: Boolean,
      default: true,
    },
    noBodyOverflow: {
      type: Boolean,
      default: false,
    },
    xAction: {
      type: Function,
      default: null,
    },
    dataTest: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return { show: false }
  },
  computed: {
    widthWithUnit() {
      return {
        ...(this.width ? { 'max-width': `${this.width}px` } : {}),
      }
    },
  },
  created() {
    this.show = true
  },
  methods: {
    clickaway() {
      this.$emit('clickaway')
    },
    close() {
      this.show = false
      this.$nextTick(() => this.$emit('close'))
    },
    closeByX() {
      this.xAction ? this.xAction() : this.close()
    },
  },
}
</script>

<style scoped>
.z-1000 {
  z-index: 1000;
}

.overflow-none {
  overflow: initial;
}

/* animations adapted from https://github.com/chenjiahan/vodal */
@keyframes fade-in {
  from {
    opacity: 0;
  }
}

.fade-in-enter-active {
  animation: fade-in 200ms both ease-in;
}

.fade-in-leave-active {
  animation: fade-in 200ms both ease-out reverse;
}

@keyframes slide-up {
  from {
    transform: translate3d(0, 25px, 0);
  }
}

.slide-up-enter-active {
  animation: slide-up 300ms both cubic-bezier(0.4, 0, 0, 1.5);
}

.slide-up-leave-active {
  animation: slide-up 200ms both reverse;
}

.modal-dialog {
  overflow-y: initial !important;
}

.modal .modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.modal .modal-body.overflow-none {
  overflow: initial;
}

.modal-regular .modal-body {
  overflow: visible !important;
  max-height: 100% !important;
}
</style>
