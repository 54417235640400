<template>
  <select
    ref="select"
    class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    :class="{
      'text-grey-darker': !isNonEmpty
    }"
    :disabled="disabled"
    :multiple="multiple"
    :id="id"
    @change="update($event.target.value)"
  >
    <option
      :selected="!isNonEmpty"
      hidden
    >
      {{ disabled ? '–' : placeholder }}
    </option>
    <option
      v-if="isNonEmpty && !hideEmptyValue"
      value=""
    >
      {{ '–' }}
    </option>
    <option
      v-for="(option, index) of normalizedOptions"
      :key="index"
      :value="option"
      :selected="isSelected(option)"
      class="text-black"
    >
      <slot
        name="option"
        :option="getOption(option)"
      >
        {{ option }}
      </slot>
    </option>
  </select>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select option',
    },
    value: {
      type: [Object, String, Number, Array],
      default: null,
    },
    trackBy: {
      type: [String, Boolean],
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hideEmptyValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    }
  },
  computed: {
    normalizedOptions() {
      return this.options.map(this.normalizeOption)
    },
    normalizedValue() {
      if (this.multiple) {
        if (Array.isArray(this.value)) {
          return this.value.map(this.normalizeOption)
        } else return []
      } else return this.normalizeOption(this.value)
    },
    isNonEmpty() {
      return this.multiple && this.value ? this.value.length : !!this.value
    },
  },
  methods: {
    getOption(optionKey) {
      return this.trackBy
        ? this.options.find((opt) => opt && opt[this.trackBy] === optionKey)
        : optionKey
    },
    normalizeOption(option) {
      return this.trackBy ? option && option[this.trackBy] : option
    },
    isSelected(optionKey) {
      if (!this.value) return false

      if (this.multiple) {
        return this.normalizedValue.includes(optionKey)
      } else {
        return this.normalizedValue === optionKey
      }
    },
    update(value) {
      if (this.multiple) {
        this.$emit(
          'input',
          Array.from(this.$refs.select.querySelectorAll('option:checked')).map(
            (el) => this.getOption(el.value)
          )
        )
      } else {
        this.$emit('input', this.getOption(value))
      }
    },
  },
}
</script>

<style scoped>
select {
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
}
</style>
