<template>
  <nav
    class="block mb-8 shadow rounded-lg cursor-pointer bg-white w-full p-4 flex lg:flex-col flex-wrap side-nav"
    aria-label="Secondary navigation"
    data-test="secondary-nav"
  >
    <BaseSidebarNav :items="sidebar" />
  </nav>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('settings', ['sidebar']),
  },
}
</script>
