export const state = () => ({
  terms: [],
  currentTerm: null,
})

export const mutations = {
  SET_CURRENT_TERM(state, term) {
    state.currentTerm = term
  },
  SET_TERMS(state, terms = []) {
    state.terms = terms.filter((el) => el.public)
  },
}

export const actions = {
  // the public catalog does NOT display historical terms, so we intentionally filter out historical terms
  // using the filterHistorical query param
  async fetchTerms({ commit, rootState, state }, { school }) {
    if (state.terms && state.terms.length) return
    const data = await this.$axios.$get(
      `${school}/general/terms?filterHistorical=true&sort=true`
    )

    if (data.terms) commit('SET_TERMS', data.terms)
  },
  async fetchCurrentTerm({ commit, rootState, state }, { school }) {
    if (state.currentTerm && state.currentTerm.id) return
    const data = await this.$axios.$get(`${school}/general/currentTerm`)

    if (data.id) commit('SET_CURRENT_TERM', data)
  },
}
