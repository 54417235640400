<template>
  <div
    class="body"
    :style="fontsConfig"
    :class="[
      $route.query.print && 'theme-container--print',
    ]"
  >
    <div
      class="flex flex-col min-h-screen"
      :style="themeVariables"
    >
      <SkipToMain />
      <div
        v-if="customBeforePageHtml"
        v-html="customBeforePageHtml"
      />
      <div class="flex flex-col layout">
        <NavBar />
        <PageBreadcrumbs
          class="pt-8"
          style="padding-left:6rem"
          :path="path"
        />
        <div class="container pt-8 px-0 md:px-0 md:flex-col lg:flex-row md:pb-8">
          <span
            class="visually-hidden"
            role="status"
          >
            <template v-if="hasAlert">Navigation complete. Page has loaded.</template>
          </span>
          <main
            id="main-content"
            role="main"
            ref="content"
            tabindex="-1"
            class="lg:w-2/3 w-full page-content pb-0 limited-width"
            aria-label="Page content"
          >
            <nuxt />
          </main>
          <div
            class="lg:w-1/3 w-full hidden md:block md:pl-8"
            data-test="secondary-nav"
          >
            <TheSidebar />
          </div>
        </div>
      </div>
      <FooterBar class="flex-no-shrink" />
      <div
        v-if="customAfterPageHtml"
        v-html="customAfterPageHtml"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'
import TheSidebar from '@/components/TheSidebar'
import PageBreadcrumbs from '@/components/PageBreadcrumbs'

import baseLayoutMixin from '@/mixins/baseLayoutMixin'
import { pagePath } from '@/utils/navigation'
import SkipToMain from '@/components/SkipToMain'

export default {
  components: { NavBar, TheSidebar, FooterBar, PageBreadcrumbs, SkipToMain },
  extends: baseLayoutMixin,
  data() {
    return {
      hasAlert: false,
    }
  },
  computed: {
    ...mapGetters('settings', ['customBeforePageHtml', 'customAfterPageHtml']),
    ...mapGetters('page', ['currentPageLabel']),
    ...mapState('settings', ['sidebar']),
    path() {
      return pagePath(this.$route, this.sidebar, this.currentPageLabel)
    },
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (newRoute.path === oldRoute.path) return
      this.$refs.content.focus()
      this.announcePage()
    },
  },
  methods: {
    announcePage() {
      this.hasAlert = true
      setTimeout(() => {
        this.hasAlert = false
      }, 1300)
    },
  },
}
</script>

<style media="screen" lang="scss">
.body {
  @apply bg-gray-100;
  font-family: var(--baseFontFace, inherit);
}

.body-font {
  font-family: var(--textFontTypeface, inherit);
}

.layout {
  flex: 1 0 auto;
}

.department-link {
  @apply rounded-full bg-theme-500 uppercase px-3 py-1 text-xs font-bold text-white inline-flex flex-grow-0 flex;
  color: white !important;
}

.department-link:hover {
  @apply bg-theme-400;
}

.page-content {
  @apply text-base leading-normal w-full relative outline-none;
}

.page-content hr {
  @apply border-b my-4 border-gray-400;
}
.page-content h1 {
  @apply mb-4 text-3xl;
  color: var(--h1HeaderFontColor, var(--theme-dark, #003525));
  font-family: var(--h1HeaderFontTypeface, inherit);
  font-weight: var(--h1HeaderFontWeight, 400);
}
.page-content h2 {
  @apply text-2xl mb-4 mt-8 pb-2 border-b;
  color: var(--h2HeaderFontColor, inherit);
  font-family: var(--h2HeaderFontTypeface, inherit);
  font-weight: var(--h2HeaderFontWeight, 400);
}
.page-content h3 {
  @apply text-xl mb-4 mt-8;
  color: var(--h3HeaderFontColor, inherit);
  font-family: var(--h3HeaderFontTypeface, inherit);
  font-weight: var(--h3HeaderFontWeight, inherit);
}
.page-content h4 {
  @apply text-lg mb-3 mt-6;
}
.page-content h5 {
  @apply text-base mb-3 mt-6;
}
.page-content p {
  @apply leading-normal text-base mb-3;
  color: var(--textFontColor, inherit);
  font-family: var(--textFontTypeface, inherit);
  font-weight: var(--textFontWeight, inherit);
}
.page-content p:empty:before {
  content: ' ';
  white-space: pre;
}
.page-content ul {
  @apply leading-normal text-base mb-3;
}
.page-content ol {
  @apply leading-normal text-base mb-3;
}
.page-content a {
  @apply font-semibold text-theme-400;
}
.page-content a:hover {
  @apply text-theme-500 underline;
}
.page-content code {
  @apply py-1 px-2 bg-gray-200;
}
.page-content blockquote {
  @apply pl-4 py-2 border-l-2 leading-normal text-lg italic text-gray-600 mb-4;
}
.page-content pre {
  @apply p-3 bg-gray-200 block mb-3 overflow-auto max-w-full break-words whitespace-normal;
}

.page-content .iframeWrapper {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
}
.page-content,
.main-content {
  table {
    @apply text-left w-full mb-4;
    border-collapse: collapse;
  }

  tr {
    @apply border-b border-gray-400;
  }

  tr:hover {
    @apply bg-gray-100;
  }

  thead,
  th {
    @apply bg-gray-200 z-10;
  }

  th {
    @apply py-2 px-4 bg-gray-200 font-semibold uppercase text-sm text-gray-600;
  }
  td {
    @apply py-2 px-4;
  }
  td,
  th,
  td p,
  th p {
    @apply mb-0 text-sm;
  }
}

body .ui-font {
  font-family: var(--interfaceFontTypeface, inherit);
  font-weight: var(--interfaceFontWeight, inherit);
  color: var(--interfaceFontColor, inherit);
}

@media (max-width: 576px) {
  .page-content table {
    position: sticky;
  }

  .page-content table,
  .page-content thead,
  .page-content tbody,
  .page-content th,
  .page-content td,
  .page-content tr {
    display: block;
  }

  .page-content thead {
    position: sticky;
    top: -1px;
  }

  .page-content thead th {
    white-space: nowrap;
  }

  .page-content tr {
    border: 1px solid #ccc;
  }

  .page-content tbody th,
  .page-content td {
    padding-left: 36px;
    min-height: 38px;
  }

  .page-content td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
  }

  .page-content tbody th:before,
  .page-content td:before {
    @apply px-4 font-bold absolute left-0;
  }

  .page-content td:nth-child(1):before,
  .page-content th:nth-child(1):before {
    content: '1. ';
  }
  .page-content td:nth-child(2):before,
  .page-content th:nth-child(2):before {
    content: '2. ';
  }
  .page-content td:nth-child(3):before,
  .page-content th:nth-child(3):before {
    content: '3. ';
  }
  .page-content td:nth-child(4):before,
  .page-content th:nth-child(4):before {
    content: '4. ';
  }
  .page-content td:nth-child(5):before,
  .page-content th:nth-child(5):before {
    content: '5. ';
  }
  .page-content td:nth-child(6):before,
  .page-content th:nth-child(6):before {
    content: '6. ';
  }
  .page-content td:nth-child(7):before,
  .page-content th:nth-child(7):before {
    content: '7. ';
  }
  .page-content td:nth-child(8):before,
  .page-content th:nth-child(8):before {
    content: '8. ';
  }
  .page-content td:nth-child(9):before,
  .page-content th:nth-child(9):before {
    content: '9. ';
  }
  .page-content td:nth-child(10):before,
  .page-content th:nth-child(10):before {
    content: '10 ';
  }
}

@screen lg {
  /* overwrite width: 100% applied by page-content class */
  .container .limited-width {
    @apply w-2/3;
  }
}
</style>
