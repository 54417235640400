const putAt = (array, index, value) => {
  const copy = array.slice()
  copy.splice(index, 1, value)
  return copy
}

const state = () => ({
  documentTypes: [],
})

const getters = {
  getAll: (state) =>
    state.documentTypes.filter((documentType) => !documentType.deletedAt),
  getAllIncludingDeleted: (state) => state.documentTypes,
  getById: (state) => (documentTypeId) =>
    state.documentTypes.find(
      (documentType) =>
        documentType.id === documentTypeId && !documentType.deletedAt
    ),
}

const mutations = {
  SET(state, documentTypes) {
    state.documentTypes = documentTypes || []
  },
  SET_ONE(state, documentType) {
    const index = state.documentTypes.findIndex(
      ({ id }) => id === documentType.id
    )
    if (index > -1) {
      state.documentTypes = putAt(state.documentTypes, index, documentType)
    } else {
      state.documentTypes = [documentType, ...state.documentTypes]
    }
  },
}

const actions = {
  async fetchById({ commit, getters }, { id, school } = {}) {
    const existingDocumentType = getters.getById(id)
    if (existingDocumentType?.id) return existingDocumentType
    try {
      const response = await this.$axios.$get(`${school}/document-types/${id}`)
      if (response instanceof Error) throw response
      if (typeof response === 'symbol') throw response
      if (response.error instanceof Error) throw response.error
      commit('SET_ONE', response)
      return response
    } catch (err) {
      console.error(err)
    }
  },
}

export { state, getters, mutations, actions }
