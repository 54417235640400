<template>
  <div
    class="flex limit-width"
    role="tablist"
  >
    <button
      v-for="(tab, index) in tabs"
      :key="tab.key"
      :data-test="tab.label + 'Tab'"
      :aria-selected="tab.key === value.key ? 'true' : 'false'"
      role="tab"
      type="button"
      :class="{ 'active-tab': tab.key === value.key, 'mr-4': index !== tabs.length-1  }"
      class="p-0 negative-offset flex-shrink-0 my-2"
      @click="$emit('input', tab)"
    >
      <slot v-bind="{ isActive: tab.key === value.key, tab }">{{ tab.label }}</slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.negative-offset {
  outline-offset: -1px;
}
.limit-width {
  width: calc(100vw - 2rem);
  /* We need a static width for the tabs container so that there is no horizontal scroll on the page */
  /* The padding on both side is being accounted for in the width calculation */
}
</style>
