import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default function({ store, app }) {
  const id = store.state.settings.settings.gtmId || process.env.GTM_ID
  Vue.use(
    VueGtag,
    {
      config: {
        id,
      },
    },
    app.router
  )
}
