export const loadInternalFont = (fontName, fontFile) => {
  if (!fontName || !fontFile) return
  const styleTag = document.createElement('style')
  styleTag.appendChild(
    document.createTextNode(`
        @font-face {
            font-family: '${fontName}';
            src: url('${fontFile}');
        }`)
  )
  document.head.appendChild(styleTag)
}

export const loadExternalFont = (fontUrl) => {
  if (!fontUrl) return
  const linkTag = document.createElement('link')
  linkTag.href = fontUrl
  linkTag.type = 'text/css'
  linkTag.rel = 'stylesheet'
  document.head.appendChild(linkTag)
}
