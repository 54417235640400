/*
 * @typedef {RegExp} RedirectInput - The input pattern to match
 * @typedef {string} RedirectOutput - The output pattern to redirect to. It can contain interpolation values:
 * - {0} for whole match
 * - {1} for the first group, {2} for the second, etc.
 * - {name} for named groups
 * Example: '/courses/{code}{number}' for input '/Student/Courses/Search/(?<code>.*)-(?<number>.*)'
 * or '/resources/{1}' for input '/Student/Resource/(.*)'
*/
export default {
  regis_colleague_ethos: [
    // Course search
    // https://www.notion.so/coursedog1/Escalation-Regis-URL-Re-Directs-5c94173700ba455f9e4f39048bd45f3a?pvs=4#a89e9757013f4526a369d378ed1d526d
    {
      input: /\/Student\/Courses\/Search\?Keyword\=(?<code>.*)-(?<number>.*)/,
      output: '/courses?cq={code}{number}',
    },
    // Other search
    // https://www.notion.so/coursedog1/Escalation-Regis-URL-Re-Directs-5c94173700ba455f9e4f39048bd45f3a?pvs=4#a89e9757013f4526a369d378ed1d526d
    {
      input: /\/Student\/Courses\/Search\?Keyword\=(?<text>.*)/,
      output: '/courses?cq={text}',
    },
    // Search by subject
    // https://www.notion.so/coursedog1/Escalation-Regis-URL-Re-Directs-5c94173700ba455f9e4f39048bd45f3a?pvs=4#6dcd5395e2bc47909d7628351938d8bc
    {
      input: /\/Student\/Courses\/Search\?subjects\=(?<subjectCode>.*)/,
      output: '/courses?subjectCode={subjectCode}',
    },
  ],
}
