<template>
  <NuxtLink
    v-if="hasLogo || hasSchoolName"
    to="/"
    class="flex items-center font-semibold text-xl tracking-tight logo-link mr-4"
  >
    <template v-if="hasLogo">
      <img
        :src="image"
        class="mr-4 img"
        alt="Catalog Home Page"
        data-test="image-nav-link" 
      >
    </template>
    <template v-if="hasSchoolName">
      <span data-test="text-nav-link" class="school-link">{{ schoolDisplayName }} Catalog </span>
    </template>
  </NuxtLink>
</template>

<script>
import { mapState } from 'vuex'
import coursedogImage from '~/static/coursedog-logo.png'

export default {
  computed: {
    ...mapState('settings', ['schoolDisplayName', 'settings']),
    homeLinkType() {
      if (
        typeof this.settings.homeLinkType === 'object' &&
        this.settings?.homeLinkType?.value
      ) {
        return this.settings?.homeLinkType?.value
      }
      return this.settings.homeLinkType
    },
    hasLogo() {
      return this.homeLinkType === 'logotype' || this.homeLinkType === 'logo'
    },
    hasSchoolName() {
      return this.homeLinkType === 'logotype' || this.homeLinkType === 'text'
    },
    image() {
      return this.settings.navImage || coursedogImage
    },
  },
}
</script>

<style lang="css" scoped>
.img {
  max-height: 40px;
}

.logo-link {
  color: white !important;
}

.school-link {
  @apply no-underline block mt-0 px-3 py-2;
  color: var(--theme-navbar-link, #ffffff);
}

.school-link:hover {
  color: var(--theme-navbar-link, #ffffff);
}
</style>
