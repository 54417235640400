<template>
  <div
    v-if="totalPages > 0"
    class="flex ml-2 px-0 text-xs ui-font base-pagination"
  >
    <BaseButton
      class="mx-1"
      type="secondary"
      aria-label="Previous page"
      :disabled="currentPageNumber === 1"
      @click="$emit('page-change', currentPageNumber - 1)"
    >
      <BaseIcon icon="chevron-left"/>
    </BaseButton>
    <BaseButton
      v-for="(page, index) in pages"
      :key="index"
      class="mx-1"
      :aria-label="`Go to page ${page.number}`"
      :aria-hidden="page.number === '...'"
      v-bind="page"
      @click="$emit('page-change', page.number)"
    >
      {{ page.number }}
    </BaseButton>
    <BaseButton
      class="mx-1"
      type="secondary"
      aria-label="Next page"
      :disabled="currentPageNumber === totalPages"
      @click="$emit('page-change', currentPageNumber + 1)"
    >
      <BaseIcon icon="chevron-right"/>
    </BaseButton>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: [Number, String],
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },

  computed: {
    currentPageNumber() {
      return typeof this.currentPage === 'number'
        ? this.currentPage
        : parseInt(this.currentPage, 10)
    },

    pages() {
      const delta = 1
      const separate = (a, b) => [
        a,
        ...({
          0: [],
          1: [b],
          2: [a + 1, b],
        }[b - a] || ['...', b]),
      ]

      return Array(delta * 2 + 1)
        .fill()
        .map((_, index) => this.currentPageNumber - delta + index)
        .filter((page) => page > 0 && page <= this.totalPages)
        .reduce((pages, page, index, { length }) => {
          if (!index) return pages.concat(separate(1, page))
          if (index === length - 1)
            return pages.concat(separate(page, this.totalPages))

          return pages.concat([page])
        }, [])
        .map((page) => {
          const isCurrent = this.currentPageNumber === page
          return {
            number: page,
            type: isCurrent ? 'primary' : 'secondary',
            disabled: isCurrent || page === '...',
          }
        })
    },
  },
}
</script>
