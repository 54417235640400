<template>
  <div class="flex flex-col mt-32 text-center" :style="themeVariables">
    <div v-if="error.name === 'NO_CATALOG'" class="error__fullscreen">
      <h1>Page not found</h1>
    </div>
    <div v-else class="mt-8">
      <h1>An error occurred</h1>
      <nuxt-link to="/" class="no-underline font-semibold text-blue mt-4 inline-block">
        Home page
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import baseLayoutMixin from '@/mixins/baseLayoutMixin'

export default {
  extends: baseLayoutMixin,
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.error__fullscreen {
  left: 0;
  position: fixed;
  top: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 100px;
}
</style>
