import { HIDE_DEPARTMENTS } from '@/constants/featureFlags'
import useFeatureFlags from '@/composables/useFeatureFlags'

export default function useGlobalSearch() {
  const { hideDepartments } = useFeatureFlags({
    hideDepartments: HIDE_DEPARTMENTS,
  })
  const searchEntities = [
    {
      id: 'catalogPages',
      label: 'Resources',
      enabled: true,
    },
    {
      id: 'programs',
      label: 'Programs',
      enabled: true,
    },
    ...(hideDepartments
      ? []
      : [
          {
            id: 'departments',
            label: 'Departments',
            enabled: true,
          },
        ]),
    {
      id: 'courses',
      label: 'Courses',
      enabled: true,
    },
  ]
  return {
    searchEntities,
  }
}
