<template>
  <div
    id="navbar"
    class="relative bg-theme-navbar-background"
    style="box-shadow: 0px 0px 6px 6px rgb(0 0 0 / 6%);"
  >
    <ResponsiveNavTop style="border-bottom: 1px solid rgba(0,0,0,0.15);">
      <template #top-nav-before>
        <slot />
      </template>
    </ResponsiveNavTop>
    <ResponsiveNavBottom />
  </div>
</template>

<script>
import ResponsiveNavTop from './ResponsiveNavTop'
import ResponsiveNavBottom from './ResponsiveNavBottom'

export default {
  components: {
    ResponsiveNavTop,
    ResponsiveNavBottom,
  },
  watch: {
    '$route.name' () {
      const root = document.querySelector('#main-content-skip-link').parentElement
      root.setAttribute('tabindex', '-1')
      root.focus()
      root.removeAttribute('tabindex')
    }
  }
}
</script>
