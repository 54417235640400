<template>
  <div>
    <slot
      name="trigger"
      v-bind="{ isOpen, setIsOpen }"
    >
      <button
        :id="`${accordionId}-header`"
        :aria-expanded="isOpen ? 'true' : 'false'"
        :aria-controls="`${accordionId}-panel`"
        data-test="accordion-button"
        class="accordion-button flex items-center justify-between w-full hover:text-theme-400 focus:outline-none"
        :class="[selected && 'selected', inNavigation && 'text-white md:text-gray-900', !inNavigation && 'text-gray-900']"
        @click="setIsOpen(!isOpen)"
        @keyup.space="setIsOpen(!isOpen)"
      >
        <slot />
        <BaseIcon
          icon="chevron-down"
          class="mr-2"
          aria-hidden="true"
          :class="{ 'rotated': isOpen }"
        />
      </button>
    </slot>
    <div
      v-show="isOpen"
      :id="`${accordionId}-panel`"
      :aria-labelledby="`${accordionId}-header`"
      :aria-hidden="isOpen ? 'false' : 'true'"
      role="region"
      class="block"
      data-test="accordion-div"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initiallyOpen: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    inNavigation: {
      type: Boolean,
      default: false,
    },
    uiId: {
      type: String,
      default: null,
    },
  },
  data: (instance) => ({
    isOpen: instance.initiallyOpen,
    backupId: Math.random().toString(36),
  }),
  computed: {
    accordionId() {
      return this.uiId || this.backupId
    },
  },
  methods: {
    setIsOpen(isOpen) {
      this.isOpen = isOpen
    },
  },
}
</script>

<style lang="css" scoped>
.rotated {
  transform: rotateX(180deg);
}
</style>
