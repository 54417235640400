const CATALOG_NOT_FOUND_CODES = [400, 418]

export const actions = {
  async nuxtServerInit({ dispatch }, { req, error, res }) {
    try {
      const host = getHost(req)
      if (process.env.NODE_ENV === 'development') {
        console.log(`Loading catalog: ${host}`)
      }
      const { school } = await dispatch('settings/fetch', {
        url: host,
      })
      await Promise.all([
        dispatch('settings/fetchDisplayName', { school }),
        // TODO: We don’t necessarily need this during SSR
        //       Might reduce the request load
        // dispatch('reports/fetchRecentCatalogPdf', { school, catalogId }),
        dispatch('departments/fetch', { school }),
      ])

      const flags = await this.$featureFlags.featureFlagsService.getAllFlags(
        school
      )

      dispatch('settings/initFlags', { flags: flags })
    } catch (err) {
      if (CATALOG_NOT_FOUND_CODES.includes(err.response?.status)) {
        console.error(err.response?.data?.error)
        error({
          statusCode: 404,
          message: 'Page not found',
          name: 'NO_CATALOG',
        })
      } else {
        this.$bugsnag.notify(err)
        throw err
      }
    }
  },
}

export function getHost(req) {
  // Use DEV_HOST if available
  if (process.env.DEV_HOST) return process.env.DEV_HOST
  if (process.env.NODE_ENV === 'development') {
    // parse cookie
    const cookieHost = decodeURIComponent(
      ((req.headers.cookie || '').match(/dev_selected_catalog=([^;]*)/) ||
        '')[1] || ''
    )
    // use cookie data if development and available
    if (cookieHost) {
      return cookieHost
    }
  }
  // fallback to req.host
  return req.headers.host
}
