export default {
  questions: {
    career: {
      config: {
        multiple: false,
        options: [],
      },
      dataKey: 'career',
      label: 'Career',
      optionsEditable: true,
      questionId: 'career',
      questionType: 'careerSelect',
      required: false,
    },
    components: {
      actions: [],
      config: {
        fields: {
          attendanceContactUse: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'attendanceContactUse',
            label: 'Contact Use',
            rolesAllowedToEdit: [],
          },
          attendanceGenerate: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'attendanceGenerate',
            label: 'Generate Attendance',
            rolesAllowedToEdit: [],
          },
          attendanceLeftUse: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'attendanceLeftUse',
            label: 'Left Use',
            rolesAllowedToEdit: [],
          },
          attendancePresentUse: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'attendancePresentUse',
            label: 'Present Use',
            rolesAllowedToEdit: [],
          },
          attendanceReasonUse: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'attendanceReasonUse',
            label: 'Reason Use',
            rolesAllowedToEdit: [],
          },
          attendanceTardyUse: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'attendanceTardyUse',
            label: 'Tardy Use',
            rolesAllowedToEdit: [],
          },
          attendanceTemplateOverride: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'attendanceTemplateOverride',
            label: 'Template Override',
            rolesAllowedToEdit: [],
          },
          attendanceTimeUse: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'attendanceTimeUse',
            label: 'Time Use',
            rolesAllowedToEdit: [],
          },
          attendanceType: {
            config: {
              options: [],
            },
            editable: true,
            hidden: false,
            inputType: 'select',
            key: 'attendanceType',
            label: 'Attendance Type',
            rolesAllowedToEdit: [],
          },
          autoCreate: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'autoCreate',
            label: 'Auto Create',
            rolesAllowedToEdit: [],
          },
          code: {
            editable: true,
            hidden: false,
            inputType: 'text',
            key: 'code',
            label: 'Code',
            rolesAllowedToEdit: [],
          },
          contactHours: {
            editable: true,
            hidden: false,
            inputType: 'number',
            key: 'contactHours',
            label: 'Instructor Contact Hours',
            rolesAllowedToEdit: [],
            rolesAllowedToSee: [],
          },
          creditHours: {
            editable: true,
            hidden: false,
            inputType: 'number',
            key: 'creditHours',
            label: 'Credit Hours',
            rolesAllowedToEdit: [],
            rolesAllowedToSee: [],
          },
          defaultSectionSize: {
            editable: true,
            hidden: false,
            inputType: 'number',
            key: 'defaultSectionSize',
            label: 'Default Section Size',
            rolesAllowedToEdit: [],
          },
          finalExamType: {
            editable: true,
            hidden: false,
            inputType: 'text',
            key: 'finalExamType',
            label: 'Final Exam Type',
            rolesAllowedToEdit: [],
          },
          includeInDynamicDate: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'includeInDynamicDate',
            label: 'Include in Dynamic Date Calc',
            rolesAllowedToEdit: [],
          },
          instructionMode: {
            editable: true,
            hidden: false,
            inputType: 'text',
            key: 'instructionMode',
            label: 'Instruction Mode',
            rolesAllowedToEdit: [],
          },
          lmsFileType: {
            editable: true,
            hidden: false,
            inputType: 'text',
            key: 'lmsFileType',
            label: 'LMS File Type',
            rolesAllowedToEdit: [],
          },
          name: {
            editable: true,
            hidden: false,
            inputType: 'text',
            key: 'name',
            label: 'Name',
            rolesAllowedToEdit: [],
          },
          oeeWorkloadHours: {
            editable: true,
            hidden: false,
            inputType: 'number',
            key: 'oeeWorkloadHours',
            label: 'OEE Workload Hours',
            rolesAllowedToEdit: [],
          },
          optionalComponent: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'optionalComponent',
            label: 'Optional Component',
            rolesAllowedToEdit: [],
          },
          preferredRoomFeatures: {
            editable: true,
            hidden: false,
            inputType: 'select',
            key: 'preferredRoomFeatures',
            label: 'Preferred Room Features',
            rolesAllowedToEdit: [],
          },
          providerForAuthentication: {
            config: {
              options: ['Yes', 'No', 'Last Class'],
            },
            editable: true,
            hidden: false,
            inputType: 'select',
            key: 'providerForAuthentication',
            label: 'Provider for Authentication',
            rolesAllowedToEdit: [],
          },
          workloadHours: {
            editable: false,
            hidden: false,
            inputType: 'number',
            key: 'workloadHours',
            label: 'Workload Hours',
            rolesAllowedToEdit: [],
          },
        },
      },
      dataKey: 'components',
      description: '',
      isRequired: true,
      label: 'Components',
      questionId: 'components',
      questionType: 'components',
      required: false,
    },
    courseNumber: {
      config: {},
      dataKey: 'courseNumber',
      label: 'Course Number',
      questionId: 'courseNumber',
      questionType: 'courseNumber',
      required: true,
    },
    credits: {
      config: {
        fields: {
          academicProgressHours: {
            editable: true,
            fields: {
              max: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'max',
                label: 'Max',
              },
              min: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'min',
                label: 'Min',
              },
              operator: {
                editable: true,
                hidden: true,
                inputType: 'select',
                key: 'operator',
                label: 'Operator',
              },
              value: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'value',
                label: 'Value',
              },
            },
            hidden: true,
            key: 'academicProgressHours',
            label: 'Academic Progreess Hours',
          },
          billingHours: {
            editable: true,
            fields: {
              max: {
                editable: true,
                hidden: false,
                inputType: 'number',
                key: 'max',
                label: 'Max',
              },
              min: {
                editable: true,
                hidden: false,
                inputType: 'number',
                key: 'min',
                label: 'Min',
              },
              operator: {
                editable: true,
                hidden: false,
                inputType: 'select',
                key: 'operator',
                label: 'Operator',
              },
              value: {
                editable: true,
                hidden: false,
                inputType: 'number',
                key: 'value',
                label: 'Value',
              },
            },
            hidden: false,
            key: 'billingHours',
            label: 'Billing Hours',
          },
          contactHours: {
            editable: true,
            fields: {
              max: {
                editable: true,
                hidden: false,
                inputType: 'number',
                key: 'max',
                label: 'Max',
                rolesAllowedToSee: [],
              },
              min: {
                editable: true,
                hidden: false,
                inputType: 'number',
                key: 'min',
                label: 'Min',
                rolesAllowedToSee: [],
              },
              operator: {
                editable: true,
                hidden: false,
                inputType: 'select',
                key: 'operator',
                label: 'Operator',
                rolesAllowedToSee: [],
              },
              value: {
                editable: true,
                hidden: false,
                inputType: 'number',
                key: 'value',
                label: 'Value',
                rolesAllowedToSee: [],
              },
            },
            hidden: false,
            key: 'contactHours',
            label: 'Contact Hours',
            rolesAllowedToSee: [],
          },
          courseCount: {
            editable: true,
            hidden: false,
            inputType: 'number',
            key: 'courseCount',
            label: 'Course Count',
          },
          creditHours: {
            editable: false,
            fields: {
              max: {
                editable: false,
                hidden: false,
                inputType: 'number',
                key: 'max',
                label: 'Max',
              },
              min: {
                editable: false,
                hidden: false,
                inputType: 'number',
                key: 'min',
                label: 'Min',
              },
              operator: {
                editable: false,
                hidden: false,
                inputType: 'select',
                key: 'operator',
                label: 'Operator',
              },
              value: {
                editable: false,
                hidden: false,
                inputType: 'number',
                key: 'value',
                label: 'Value',
              },
            },
            hidden: false,
            key: 'creditHours',
            label: 'Credit Hours',
          },
          financialAidHours: {
            editable: true,
            fields: {
              max: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'max',
                label: 'Max',
              },
              min: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'min',
                label: 'Min',
              },
              operator: {
                editable: true,
                hidden: true,
                inputType: 'select',
                key: 'operator',
                label: 'Operator',
              },
              value: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'value',
                label: 'Value',
              },
            },
            hidden: true,
            key: 'financialAidHours',
            label: 'Financial Aid Hours',
          },
          labHours: {
            editable: true,
            fields: {
              max: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'max',
                label: 'Max',
              },
              min: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'min',
                label: 'Min',
              },
              operator: {
                editable: true,
                hidden: true,
                inputType: 'select',
                key: 'operator',
                label: 'Operator',
              },
              value: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'value',
                label: 'Value',
              },
            },
            hidden: true,
            key: 'labHours',
            label: 'Lab Hours',
          },
          lectureHours: {
            editable: true,
            fields: {
              max: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'max',
                label: 'Max',
              },
              min: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'min',
                label: 'Min',
              },
              operator: {
                editable: true,
                hidden: true,
                inputType: 'select',
                key: 'operator',
                label: 'Operator',
              },
              value: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'value',
                label: 'Value',
              },
            },
            hidden: true,
            key: 'lectureHours',
            label: 'Lecture Hours',
          },
          numberOfCredits: {
            editable: true,
            hidden: false,
            inputType: 'number',
            key: 'numberOfCredits',
            label: 'Number Of Credits',
          },
          numberOfRepeats: {
            editable: true,
            hidden: false,
            inputType: 'number',
            key: 'numberOfRepeats',
            label: 'Number Of Repeats',
          },
          otherHours: {
            editable: true,
            fields: {
              max: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'max',
                label: 'Max',
              },
              min: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'min',
                label: 'Min',
              },
              operator: {
                editable: true,
                hidden: true,
                inputType: 'select',
                key: 'operator',
                label: 'Operator',
              },
              value: {
                editable: true,
                hidden: true,
                inputType: 'number',
                key: 'value',
                label: 'Value',
              },
            },
            hidden: true,
            key: 'otherHours',
            label: 'Other Hours',
          },
          repeatable: {
            editable: true,
            hidden: false,
            inputType: 'yesNo',
            key: 'repeatable',
            label: 'Repeatable',
          },
        },
      },
      dataKey: 'credits',
      isRequired: true,
      label: 'Credits',
      questionId: 'credits',
      questionType: 'credits',
      required: false,
    },
    departments: {
      actions: [],
      config: {},
      dataKey: 'departments',
      isRequired: true,
      label: 'Department(s)',
      questionId: 'departments',
      questionType: 'departmentSelect',
      required: false,
    },
    description: {
      config: {},
      dataKey: 'description',
      isRequired: true,
      label: 'Description',
      questionId: 'description',
      questionType: 'textarea',
      required: false,
    },
    requisites: {
      actions: [],
      config: {
        fields: {
          requisitesAdvanced: {
            key: 'requisitesAdvanced',
            label: 'Advance Requisites',
          },
          requisitesFreeform: {
            key: 'requisitesFreeform',
            label: 'Free Form Requisites',
          },
          requisitesSimple: {
            key: 'requisitesSimple',
            label: 'Simple Requisites',
          },
        },
      },
      dataKey: 'requisites',
      isRequired: true,
      label: 'Requisites',
      questionId: 'requisites',
      questionType: 'requisites',
      required: false,
    },
    subjectCode: {
      config: {},
      dataKey: 'subjectCode',
      label: 'Subject code',
      questionId: 'subjectCode',
      questionType: 'subjectCodeSelect',
      required: true,
    },
  },
  template: [
    {
      children: [
        {
          children: [
            {
              id: 'subjectCode',
              type: 'question',
            },
            {
              id: 'courseNumber',
              type: 'question',
            },
            {
              id: 'departments',
              type: 'question',
            },
          ],
          id: 'oX4yN',
          type: 'row',
        },
        {
          children: [
            {
              id: 'description',
              type: 'question',
            },
          ],
          id: 'frqQg',
          type: 'row',
        },
        {
          children: [
            {
              id: 'startTerm',
              type: 'question',
            },
            {
              id: 'career',
              type: 'question',
            },
          ],
          id: 'p3e8d',
          type: 'row',
        },
      ],
      config: {
        icon: 'book',
        id: 'aoYks',
        title: 'Overview',
      },
      id: 'aoYks',
      type: 'card',
    },
    {
      children: [
        {
          children: [
            {
              id: 'credits',
              type: 'question',
            },
          ],
          id: 'ywmko',
          type: 'row',
        },
        {
          children: [
            {
              id: 'components',
              type: 'question',
            },
          ],
          id: 'XDjbH',
          type: 'row',
        },
      ],
      config: {
        icon: 'book',
        id: 'dI69N',
        title: 'Credits',
      },
      id: 'dI69N',
      type: 'card',
    },
    {
      children: [
        {
          children: [
            {
              id: 'requisites',
              type: 'question',
            },
          ],
          id: 'OD4rE',
          type: 'row',
        },
      ],
      config: {
        icon: 'book',
        id: 'V3h05',
        title: 'Requisites',
      },
      id: 'V3h05',
      type: 'card',
    },
  ],
}
