import { reactive, isReactive, computed, watch } from '@vue/composition-api'
import { useContext } from '@nuxtjs/composition-api'
import { featureFlagsClient, loadLaunchDarkly } from '../utils/launchDarkly'

let flags = {
  loaded: false, // indicates initial loading state
  upToDate: false, // indicates available updates
  settings: {}, // the actual feature flags
}

function setFeatureFlags(newFlags = {}) {
  flags.settings = { ...flags.settings, ...newFlags }
  flags.upToDate = true
  flags.loaded = true
}

function initializeFeatureFlags() {
  // composition API cannot be used in module scope (not initialized yet)
  // so it has to be done on initialization
  // ATTENTION: Although the flags object is reactive, flags themselves are
  //            only updated on first load or school change
  if (!isReactive(flags)) flags = reactive(flags)
  const { store } = useContext()

  const key = computed(() => store.state.settings.school)

  const user = computed(() => {
    if (!key.value) return { key: 'anonymous' }

    return {
      key: key.value,
      custom: { school: key.value },
    }
  })

  // reload feature flags when school changes
  // identify takes a callback that's called with new flags after identification
  watch([key], () => {
    if (!key.value) return
    featureFlagsClient.identify(user.value, null, setFeatureFlags)
  })

  loadLaunchDarkly(user.value)

  featureFlagsClient.on('ready', () => {
    setFeatureFlags(featureFlagsClient.allFlags())
  })

  // instead of throwing sudden changes at the user we mark the flags as
  // outdated and let the UI decide what to do with it
  featureFlagsClient.on('change', (_changedFlags) => {
    flags.upToDate = false
  })
}

/*
 * USAGE: useFeatureFlags({ flag1: product.cm.flag1, flag2: some.other.path })
 * RETURNS: Object {
 *   flags: Object containing all flags
 *   flag1: getter returning flags['product.cm.flag1']
 *   flag2: getter returning flags['some.other.path']
 * }
 */
export default function useFeatureFlags(defaultFlags = {}) {
  let featureFlags = {}
  const { store } = useContext()
  const featureFlagsFromStore = store.state.settings.featureFlags

  if (process.client) {
    // In case it's the client then:
    // - If the flags has not been laoded then initialise LD
    // - initialise the flags from the flags that has been passed from
    //   the backend and saved in the store
    if (!flags.loaded) {
      initializeFeatureFlags()
      setFeatureFlags(featureFlagsFromStore)
    }
    featureFlags = {
      flags: flags.settings,
      ready: computed(() => flags.loaded),
    }
  } else {
    // In case it's in the server side:
    // - set the feature flags from the ones that are present
    //   in the vuex store
    setFeatureFlags(featureFlagsFromStore)
    featureFlags = {
      flags: featureFlagsFromStore,
      ready: computed(() => flags.loaded),
    }
  }

  Object.keys(defaultFlags).forEach((name) => {
    // we're not doing those things here
    if (name === 'flags' && name === 'ready')
      return console.warn('illegal flag name', name)

    const flagKey = defaultFlags[name]

    Object.defineProperty(featureFlags, name, {
      get: () => flags.settings[flagKey],
    })
  })

  return featureFlags
}
