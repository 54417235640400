import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _22139184 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _0e1ceac1 = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _698f882a = () => interopDefault(import('../pages/departments/index.vue' /* webpackChunkName: "pages/departments/index" */))
const _81a99ac8 = () => interopDefault(import('../pages/programs/index.vue' /* webpackChunkName: "pages/programs/index" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _69b8a969 = () => interopDefault(import('../pages/courses/_id.vue' /* webpackChunkName: "pages/courses/_id" */))
const _18d58d12 = () => interopDefault(import('../pages/departments/_id.vue' /* webpackChunkName: "pages/departments/_id" */))
const _60f3518a = () => interopDefault(import('../pages/departments/_id/courses.vue' /* webpackChunkName: "pages/departments/_id/courses" */))
const _b6b4d032 = () => interopDefault(import('../pages/departments/_id/faculty.vue' /* webpackChunkName: "pages/departments/_id/faculty" */))
const _427ebc06 = () => interopDefault(import('../pages/departments/_id/overview.vue' /* webpackChunkName: "pages/departments/_id/overview" */))
const _2d79d408 = () => interopDefault(import('../pages/departments/_id/programs.vue' /* webpackChunkName: "pages/departments/_id/programs" */))
const _fdffa616 = () => interopDefault(import('../pages/pages/_id.vue' /* webpackChunkName: "pages/pages/_id" */))
const _562f5979 = () => interopDefault(import('../pages/preview/_id.vue' /* webpackChunkName: "pages/preview/_id" */))
const _dc45a7f8 = () => interopDefault(import('../pages/programs/_id.vue' /* webpackChunkName: "pages/programs/_id" */))
const _3ecd551e = () => interopDefault(import('../components/custom-page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _22139184,
    name: "404"
  }, {
    path: "/courses",
    component: _0e1ceac1,
    name: "courses"
  }, {
    path: "/departments",
    component: _698f882a,
    name: "departments"
  }, {
    path: "/programs",
    component: _81a99ac8,
    name: "programs"
  }, {
    path: "/search",
    component: _3d268e12,
    name: "search"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/courses/:id",
    component: _69b8a969,
    name: "courses-id"
  }, {
    path: "/departments/:id",
    component: _18d58d12,
    name: "departments-id",
    children: [{
      path: "courses",
      component: _60f3518a,
      name: "departments-id-courses"
    }, {
      path: "faculty",
      component: _b6b4d032,
      name: "departments-id-faculty"
    }, {
      path: "overview",
      component: _427ebc06,
      name: "departments-id-overview"
    }, {
      path: "programs",
      component: _2d79d408,
      name: "departments-id-programs"
    }]
  }, {
    path: "/pages/:id?",
    component: _fdffa616,
    name: "pages-id"
  }, {
    path: "/preview/:id?",
    component: _562f5979,
    name: "preview-id"
  }, {
    path: "/programs/:id",
    component: _dc45a7f8,
    name: "programs-id"
  }, {
    path: "*",
    component: _3ecd551e,
    name: "custom-page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
