import _get from 'lodash/get'

const MATCH_PATTERN = /\{([^}]+)\}/g
const SPLIT_PATTERN = /({[^}]*})/g
const CLEAN_PATTERN = /^\{(.+)\}$/
const MAX_CREDITS_FIELD = 'credits.creditHours.max'
const MIN_CREDITS_FIELD = 'credits.creditHours.min'

function stringify(value) {
  if (value && Array.isArray(value)) {
    return value.join(', ')
  }
  return value
}

function getPatternWithCredits(template = '', values = {}) {
  // Trim maxCredits and the dash from the template when maxCredits = minCredits
  if (
    _get(values, MAX_CREDITS_FIELD) === _get(values, MIN_CREDITS_FIELD) &&
    template.includes(MAX_CREDITS_FIELD)
  ) {
    const patternArray = template.split(SPLIT_PATTERN)
    return patternArray
      .map((part, index) => {
        if (
          part.includes(MAX_CREDITS_FIELD) ||
          // part between minCredits and maxCredits that indicates a range
          (patternArray[index - 1]?.includes(MIN_CREDITS_FIELD) &&
            patternArray[index + 1]?.includes(MAX_CREDITS_FIELD))
        ) {
          return ''
        }
        return part
      })
      .join('')
  }
  return template
}

export function calculateDerivedValue(template = '', values = {}) {
  const parsedTemplate = getPatternWithCredits(template, values)
  return parsedTemplate.replace(MATCH_PATTERN, (part) => {
    if (part) {
      const prop = part.replace(CLEAN_PATTERN, '$1')
      const derivedValue = stringify(
        _get(values, prop) || _get(values.customFields, prop)
      )
      if (derivedValue) return derivedValue
    }
    return ''
  })
}

export function formatDictionaryFromPageTemplate(pageTemplate = {}) {
  const pageTemplateDictionary = pageTemplate.dictionary || {}
  const pageQuestions = pageTemplate.questions || {}
  const formattedDictionary = {}
  Object.keys(pageTemplateDictionary).forEach((dictionaryKey) => {
    const questionDataKey = pageTemplateDictionary[dictionaryKey]
    if (questionDataKey) {
      const template = pageQuestions[questionDataKey]?.config?.value || ''
      formattedDictionary[dictionaryKey] = template
    }
  })
  return formattedDictionary
}
