<template>
  <div class="container">
    <div class="w-full text-xs mb-3">
      <a
        v-if="recentCatalogPdf"
        class="ml-1 underline text-xs mb-3"
        :href="recentCatalogPdf.url"
        :title="`Generated ${generatedAgo}`"
      >
        Download Catalog as PDF
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { formatRelative } from 'date-fns'

export default {
  mounted() {
    this.$store.dispatch('reports/fetchRecentCatalogPdf', {
      school: this.school,
      catalogId: this.activeCatalog,
    })
  },
  computed: {
    ...mapState('reports', ['recentCatalogPdf']),
    ...mapState('settings', ['activeCatalog', 'school']),
    generatedAgo() {
      return formatRelative(
        new Date((this.recentCatalogPdf || {}).generatedAt),
        new Date()
      )
    },
  },
}
</script>
