/**
 * @file Plugin which is to be used only on the client side
 * Hence this should be imported as following in the nuxt config
 * { src: '~/plugins/devTools.js', mode: 'client' }
 *
 * The localstorage value is to be set by the LD flag
 */

import Vue from 'vue'

Vue.config.devtools =
  process.env.NODE_ENV === 'development' ||
  !!localStorage.getItem('__SECRETLY__ENABLE__VUE_DEVTOOLS__')
