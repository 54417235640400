<template functional>
  <button
    v-bind="data.attrs"
    :class="[
      `btn btn-${props.type}`,
      data.staticClass,
      data.class
    ]"
    :type="props.submit ? 'submit' : 'button'"
    :disabled="props.disabled"
    v-on="listeners"
  >
    <slot/>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    submit: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.btn {
  @apply font-bold py-2 px-4 rounded;
}

.btn-primary {
  @apply bg-theme-500 text-white;
}

.btn-primary:hover {
  @apply bg-theme-600;
}

.btn-secondary {
  @apply bg-gray-200 text-theme-600;
}

.btn-secondary:hover {
  @apply bg-white;
}

.btn-danger {
  @apply bg-red-500 text-white;
}

.btn-danger:hover {
  @apply bg-red-600;
}

.btn[disabled] {
  pointer-events: none;
  opacity: 0.8;
}
</style>
