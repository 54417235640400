export function assembleQueryString(query = {}) {
  const searchParams = new URLSearchParams(query)
  return searchParams.toString()
}

export function assembleUrl(url, query = {}) {
  return `${url}?${assembleQueryString(query)}`.replace(/\?$/, '')
}

const prebuildUrlsMap = {
  '': '/', // home link has empty pageId
  programs: '/programs',
  courses: '/courses',
  departments: '/departments',
}

export function prebuildUrlCorrector(link) {
  return {
    ...link,
    ...(prebuildUrlsMap[link.pageId] && {
      url: prebuildUrlsMap[link.pageId],
    }),
  }
}
