<template>
  <a
    class="skip-to-main absolute overflow-hidden"
    @click.stop.prevent="focusMainContent"
    :id="id"
    :href="href"
  >{{ label }}</a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: '#main-content',
    },
    label: {
      type: String,
      default: 'Skip to Main Content',
    },
  },
  computed: {
    id () {
      return this.href.replace('#', '') + '-skip-link'
    }
  },
  methods: {
    focusMainContent() {
      const mainContent = document.querySelector(this.href)
      if (mainContent) {
        mainContent.tabIndex = -1
        mainContent.focus()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.skip-to-main {
  @apply top-auto;
  left: -999px;
  width: 1px;
  height: 1px;
  z-index: -999;
  &:focus,
  &:active {
    @apply text-center left-auto top-auto p-4 rounded-2xl text-xl overflow-auto h-auto w-auto;
    color: #fff;
    background-color: #000;
    width: 30%;
    margin: 10px 35%;
    border: 4px solid yellow;
    z-index: 999;
  }
}
</style>
